import { ExamCellActions } from "./action";

const initialState = {
  ExamCellData: [],
  ExamCellAbout: [],
  ExamCellUniRes: [],
  ExamCellUsefulLinks: [],
  ExamCellContactUs: [],
  InternalCirculars: [],
  AnnaUniversityCirculars: [],
};

export const ExamCellReducer = (state = initialState, action) => {
  switch (action.type) {
    case ExamCellActions.SET_EXAM_CELL:
      return {
        ...state,
        ExamCellData: action.payload,
      };

    case ExamCellActions.SET_EXAM_CELL_ABOUT:
      return {
        ...state,
        ExamCellAbout: action.payload,
      };

    case ExamCellActions.SET_EXAM_CELL_UNIRESULT:
      return {
        ...state,
        ExamCellUniRes: action.payload,
      };

    case ExamCellActions.SET_EXAM_CELL_USEFUL_LINKS:
      return {
        ...state,
        ExamCellUsefulLinks: action.payload,
      };

    case ExamCellActions.SET_EXAM_CELL_CONTACTUS:
      return {
        ...state,
        ExamCellContactUs: action.payload,
      };

    case ExamCellActions.SET_EXAM_CELL_INTERNAL_CIRCULARS:
      return {
        ...state,
        InternalCirculars: action.payload,
      };
    case ExamCellActions.SET_ANNA_UNIVERSITY_CIRCULARS:
      return {
        ...state,
        AnnaUniversityCirculars: action.payload,
      };

    default:
      return state;
  }
};
