import React, { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

const Gallery = () => {
  const location = useLocation();
  const { title, description, images } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); 

    return () => clearTimeout(timer); 
  }, [images]);

  useEffect(() => {
    if (images && images.length > 0) {
      Fancybox.bind('[data-fancybox="gallery"]');
    }
    return () => {
      Fancybox.destroy();
    };
  }, [images]);
  const routeNames = {
    "/": {
      menuName: "News And Events",
    },
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      {/* <Header/> */}
      <HeaderBackground
        menuName="News And Events"
        handleMenuToggle={handleMenuToggle}
      />

      <Container>
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div> 
          </div>
        ) : (
          <section className="main-grid">
            <div className="gallery-head">
              <h1 className="text-center h1 pb-40 gallery-heading">{title}</h1>
              <p className="mb-30 ml-20 mr-15 gallery-text">{description}</p>
            </div>
            <div className="container">
              {images && images.length > 0 ? (
                images.map((image, index) => (
                  <div className="card" key={index}>
                    <div className="card-image">
                      <a
                        href={image.image}
                        data-fancybox="gallery"
                        data-caption={image.content || "Image Gallery"}
                      >
                        <img src={image.image} alt={`Gallery ${index}`} />
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </section>
        )}
      </Container>
    </div>
  );
};

export default Gallery;
