import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { TheTrustAction } from "./action";

export const TheTrustSagas = function* () {
  yield all([
    takeEvery(TheTrustAction.GET_THE_TRUST_INFO_MSG, getTheTrustInfoandMsg),
  ]);
};

const getTheTrustInfoandMsg = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/trust`));

    yield put({
      type: TheTrustAction.SET_THE_TRUST_INFO_MSG,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
