export const FooterActions = {
  GET_FOOTER_DETAILS: "GET_FOOTER_DETAILS",
  SET_FOOTER_DETAILS: "SET_FOOTER_DETAILS",

  GET_REPORTS_TITLE: "GET_REPORTS_TITLE",
  SET_REPORTS_TITLE: "SET_REPORTS_TITLE",

  GET_REPORTS_DATA: "GET_REPORTS_DATA",
  SET_REPORTS_DATA: "SET_REPORTS_DATA",
};
