import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useDispatch, useSelector } from "react-redux";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { CollaborationActions } from "../../../Store/Collaboration/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Collaboration() {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("Collaboration");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openKeys, setOpenKeys] = useState([]);
  const dispatch = useDispatch();

  const routeNames = {
    "/life-at-campus": {
      menuName: "Life At Campus",
      subMenuName: "Collaboration",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  useEffect(() => {
    dispatch({ type: CollaborationActions.GET_COLLABORATION });
  }, [dispatch]);

  const { CollaborationData } = useSelector((state) => state.CollaborationReducer);
  const CollabrationFronts = CollaborationData?.data?.CollabrationFronts || [];

  useEffect(() => {
    if (CollaborationData) {
      setIsLoading(false);
    }
  }, [CollaborationData]);

  // const LifeAtCampusData = [
  //   { category: "Curricular" },
  //   { category: "Co-Curricular" },
  //   { category: "Extra-Curricular" },
  //   { category: "Collaboration" },
  // ];

  // const uniqueCategories = Array.from(
  //   new Set(LifeAtCampusData.map((item) => item.category))
  // );

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleThumbnailClick = (collaborationId) => {
    const detailImages = CollaborationData?.data?.CollabrationDetails?.filter(
      (item) => item.collaboration_id === collaborationId
    ).map((item) => ({
      src: item.image,
      opts: { caption: item.name || "Detail Image" },
    }));

    if (detailImages.length > 0) {
      Fancybox.show(detailImages, {
        infinite: true,
        buttons: ["zoom", "close", "fullscreen"],
        loop: true,
      });
    }
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        />
        <section className="work-stand section-padding sub-bg cocurricular-title">
          <div className="container">
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
                  {/* {uniqueCategories.map((category, index) => (
                    <span
                      key={index}
                      className={category === activeCategory ? "active" : ""}
                      onClick={() => handleCategoryChange(category)}
                    >
                      {category}
                    </span>
                  ))} */}
                     <div className="bord  d-flex align-items-center">
          <h4 className=" fw-300 ">Collaboration</h4>
          
          </div>
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg">
              {isLoading ? (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              ) : CollabrationFronts.length > 0 ? (
                CollabrationFronts.map((item) => (
                  <div
                    key={item.id}
                    className="col-lg-3 col-md-6 col-sm-12 mb-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleThumbnailClick(item.id)}
                  >
                    <div className="archi-card-container mt-30">
                      <div
                        className="archi-card-image-lifeatcampus pro-img"
                        style={{
                          backgroundImage: `url(${item.catagory_image})`,
                        }}
                      ></div>
                      <div className="archi-card-content">
                        <p className="archi-card-author">
                          {item.catagory_name.slice(0, 23)}...
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Collaboration;
