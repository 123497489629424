import { AcademictimetableActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const AcademictimetableSagas = function* () {
  yield all([
    takeEvery(
      AcademictimetableActions.GET_ACADEMIC_TIMETABLE,
      getAcademictimetableInfo
    ),
  ]);
};

const getAcademictimetableInfo = function* (action) {
  try {
    const year = action.payload?.year;

    if (!year) {
      console.error("Error: Year is missing in payload");
      return;
    }

    const result = yield call(() =>
      axios.get(`${API_URL}/academic_timetable/${year}`)
    );

    yield put({
      type: AcademictimetableActions.SET_ACADEMIC_TIMETABLE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching circulars:", error.response || error);
  }
};
