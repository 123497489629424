import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Principal() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/principal": { menuName: "The People", subMenuName: "Principal" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    AOS.init({
      delay: 50,
      duration: 1000,
    });
  }, []);

  const { Principalinfo } = useSelector((state) => state?.PrincipalReducer);
  const principalData = Principalinfo?.[0];

  if (!principalData) return <div>Loading...</div>;

  // Split description into paragraphs
  const descriptionParagraphs =
    principalData?.description
      ?.split("\r\n")
      .filter((paragraph) => paragraph.trim() !== "") || [];

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <main className="main-bg blog-wrap">
        {/* Principal Section */}
        <section className="about sub-bg responsive-principal" data-scroll-index="2">
          <div className="container mt-30 princi-res-fixing">
            <div className="row md-marg manage-wrap trustee-section">
              {/* Principal Message */}
              <div className="col-lg-6 trustee-message">
                {/* <h6 className="sub-title main-color mb-15 fw-320">
                  Principal's Message
                </h6> */}
                <h2 className="fw-400 d-rotate wow fz-35">
                  <span className="rotate-text trustee-msg trustee-name princi-name-ress ">
                    {principalData?.name || "Name not available"}
                  </span>
                  {/* <h6 className="text-u ls1 opacity-8 rotate-text fw-400 trustee-texts">
                    Principal
                  </h6> */}
                  <div className="info">
                    <p className="nowrap">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                          ></path>
                        </svg>
                      </span>
                      &nbsp; &nbsp; {principalData?.number}
                    </p>
                    <p >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                          ></path>
                        </svg>
                      </span>
                      &nbsp; &nbsp; {principalData?.mail}
                    </p>
                  </div>
                </h2>
                <div className="d-flex align-items-end mt-30 trust-paragraph">
                  <div>
                    {descriptionParagraphs.slice(0, 2).map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
              {/* Principal Image */}
              <div className="col-lg-6 trustee-image-container">
                <div >
                {/* <div className="img-vid"> */}
                  {principalData?.file ? (
                    <img
                      src={principalData.file}
                      alt="Principal"
                      className="trustee-image"
                    />
                  ) : (
                    <p>Image not available</p>
                  )}
                </div>
              </div>
            </div>
            {/* Remaining Description */}
            <div className="row md-marg manage-wrap trustee-section trust-paragraph">
              {descriptionParagraphs.slice(2).map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Principal;
