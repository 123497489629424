import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SideNavigation from "../../components/Menu-sidenavbar/SideNavigation ";
import { ConnectWithUsActions } from "../../Store/Connect With Us/action";
import HeaderBackground from "../../common/SubpagesBGimage";
import ReCAPTCHA from "react-google-recaptcha";
import { sitekey } from "../../utils/Constant";
const ConnectWithUs = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const [openKeys, setOpenKeys] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState("");
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: ConnectWithUsActions.GET_CONNEC_WITH_US });
  }, [dispatch]);

  const { ConnectWithUsData } = useSelector(
    (state) => state?.ConnectWithUsReducer
  );
  const contact = ConnectWithUsData?.contact?.[0];
  const socialLinks = ConnectWithUsData?.Social_links?.[0];

  const routeNames = {
    "/ConnectWithus": {
      menuName: "Connect with us",
      subMenuName: "",
    },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setError(""); // Clear error when user starts typing
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { name, email, number, message } = formValues;
    let errorMessages = []; // Collect all errors

    if (!captchaValue) {
      errorMessages.push("Please complete the ReCAPTCHA verification.");
    }

    if (!name) {
      errorMessages.push("Full Name is required.");
    }
    if (!email) {
      errorMessages.push("Email is required.");
    }
    if (!number) {
      errorMessages.push("Subject is required.");
    }
    if (!message) {
      errorMessages.push("Message is required.");
    }

    if (errorMessages.length > 0) {
      setError(errorMessages); // Set all error messages
      return;
    }

    console.log("Form submitted successfully:", formValues);
    setShowForm(false);
    setFormValues({ name: "", email: "", subject: "", message: "" });
    setCaptchaValue(null); // Reset Captcha
    setError(""); // Clear errors
  };

  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          handleMenuToggle={handleMenuToggle}
        />

        <section className="contact section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 valign">
                <div className="sec-head info-box full-width md-mb80">
                  <div className="phone fz-30 fw-600 connect-with-us main-color">
                    <a href={`tel:${contact?.contact_no_1}`}>
                      {contact?.contact_no_1}
                    </a>
                  </div>
                  {contact?.contact_no_2 && (
                    <div className="phone fz-30 fw-600 connect-with-us main-color">
                      <a href={`tel:${contact?.contact_no_2}`}>
                        {contact?.contact_no_2}
                      </a>
                    </div>
                  )}
                  <div className="morinfo mt-50 pb-30 bord-thin-bottom">
                    <h6 className="mb-15">Address</h6>
                    <p>{contact?.address}</p>
                  </div>
                  <div className="morinfo mt-30 pb-30 bord-thin-bottom">
                    <h6 className="mb-15">Email</h6>
                    <p>{contact?.mail}</p>
                  </div>
                  <div className="social-icon-circle mt-50">
                    {socialLinks?.facebook && (
                      <a
                        href={socialLinks.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    )}
                    {socialLinks?.instagram && (
                      <a
                        href={socialLinks.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    )}
                    {socialLinks?.twitter && (
                      <a
                        href={socialLinks.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    )}
                  </div>
                  <Button
                    variant="primary mt-3"
                    onClick={() => setShowForm(true)}
                  >
                    Connect with Us
                  </Button>
                </div>
              </div>
              <div className="col-lg-7 offset-lg-1 valign">
                <div className="full-width">
                  <div className="sec-head mb-50">
                    <h6 className="sub-title main-color mb-15">Location</h6>
                  </div>
                  <div className="google-map">
                    <iframe
                      id="gmap_canvas"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7831.285429470795!2d77.092779!3d11.065391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f8a655555555%3A0xa3da0cb94e76285c!2sPSG%20Institute%20of%20Technology%20and%20Applied%20Research%20(PSG%20iTech)!5e0!3m2!1sen!2sin!4v1723209161324!5m2!1sen!2sin"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      style={{ width: "100%", height: "450px", border: "0" }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit} className="form-container">
            <div className="form-group">
              <label htmlFor="name" style={{ marginRight: "auto" }}>
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleFormChange}
                className="form-control"
                placeholder="Enter Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ marginRight: "auto" }}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleFormChange}
                className="form-control"
                placeholder="example@gmail.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="number" style={{ marginRight: "auto" }}>
                Phone
              </label>
              <input
                type="number"
                id="number"
                name="number"
                value={formValues.number}
                onChange={handleFormChange}
                className="form-control"
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" style={{ marginRight: "auto" }}>
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formValues.message}
                onChange={handleFormChange}
                className="form-control"
                placeholder="Enter your message"
                required
              ></textarea>
            </div>
            <ReCAPTCHA
              sitekey={sitekey}
              onChange={(value) => setCaptchaValue(value)}
            />
            {/* Display Error Messages */}
            {error && (
              <div className="text-danger mt-3">
                {Array.isArray(error)
                  ? error.map((errMsg, index) => (
                      <div key={index}>{errMsg}</div>
                    ))
                  : error}
              </div>
            )}
            <Button type="submit" variant="primary" className="submit-btn mt-3">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default ConnectWithUs;
