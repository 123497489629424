import React, { useState, useEffect, useRef, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

const PortfolioTab = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_STUDENTPORTFOLIO });
  }, [dispatch]);

  const { StudentPortfolio, portfolioHeadings } = useSelector(
    (state) => state.HomePageReducer
  );
  // console.log("StudentPortfolio", StudentPortfolio);
  // console.log("portfolioHeadings", portfolioHeadings);
  

  const [currentTab, setCurrentTab] = useState("tab-1");
  const [animateHeading, setAnimateHeading] = useState(false);
  const [animatePortfolio, setAnimatePortfolio] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  // Handle Intersection Observer
  const handleIntersection = (entries, observer, setAnimation, setScrolledIntoView) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !hasScrolledIntoView) {
        setAnimation(true);
        setScrolledIntoView(true);
      }
    });
  };

  const useIntersectionObserver = (setAnimation) => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries, observer) => handleIntersection(entries, observer, setAnimation, setHasScrolledIntoView),
        { root: null, rootMargin: "0px", threshold: 0.5 }
      );
      if (headingRef.current) observer.observe(headingRef.current);
      return () => observer.disconnect();
    }, [hasScrolledIntoView]);
  };

  useIntersectionObserver(setAnimateHeading);
  useIntersectionObserver(setAnimatePortfolio);

  const handleTextHover = (tabId) => setCurrentTab(tabId);
  const handleTextClick = (tabId) => setCurrentTab(tabId);

  const navigateToProjectDetail = (item) => {
    navigate("/ProjectDetail", {
      state: { projectId: item.id },
    });
  };

  return (
    <section className="portfolio-tab crev section-padding" data-scroll-index="3">
      <div className="container mt-70 portfolio-title-res">
        <div className="sec-head mb-80">
          <div className="pt-25 d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 d-rotate wow ${animateHeading ? "heading-animation" : ""}`}
            >
              <span className="rotate-text portfolio-text home-heading-text">
                {portfolioHeadings?.[0]?.heading || "ACADEMIC SPOTLIGHT"}
              </span>
            </h2>
          </div>
        </div>

        <div className="row">
          {/* Left Section - Portfolio Images */}
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="glry-img">
              {Array.isArray(StudentPortfolio) && StudentPortfolio.length > 0 ? (
                StudentPortfolio.map((item, index) => (
                  <div
                    key={index}
                    className={`bg-img tab-img ${currentTab === `tab-${index + 1}` ? "current" : ""}`}
                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                  ></div>
                ))
              ) : (
                <p>Loading....</p>
              )}
            </div>
          </div>

          {/* Right Section - Project Details */}
          <div className="col-lg-6 offset-lg-1 content portfolio-tabss">
            {StudentPortfolio?.map((item, index) => (
              <div
                key={index}
                className={`student-list-project-res cluom mb-30 current ${animatePortfolio ? "heading-animation" : ""}`}
                onMouseEnter={() => handleTextHover(`tab-${index + 1}`)}
                onClick={() => handleTextClick(`tab-${index + 1}`)}
                style={{
                  backgroundImage: currentTab === `tab-${index + 1}` && window.innerWidth <= 768 ? `url(${item.thumbnail})` : "",
                  backgroundSize: "cover",
                  objectPosition: "center",
                  border: "none",
                }}
              >
                <div
                  className="info"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigateToProjectDetail(item)}
                >
                  <h6 className={`sub-title opacity-7 fadeInUp ${animatePortfolio ? "portfolio-tab" : ""}`}>
                    {item.studentname}
                  </h6>
                  <h4 className={`stu-proj-name fadeInUp stu-proj-name ${animatePortfolio ? "portfolio-tab" : ""}`}>{item.projectname}</h4>
                  {/* You can display more information here if needed */}
               
                </div>

                <div className="more text-u ls1 fz-12" style={{ cursor: "pointer" }}>
                  <a onClick={() => navigateToProjectDetail(item)}>View Project</a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flr mt-30">
          <a href="/StudentsProjectLists" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right portfolio-text"></span>
          </a>
        </div>
      </div>
    </section>
  );
});

export default PortfolioTab;



