import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";

function AlliedFaculty() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const { AlliedFacultyData } = useSelector(
    (state) => state?.AlliedFacultyReducer
  );

  const routeNames = {
    "/allied-faculty": {
      menuName: "The People",
      subMenuName: "Allied Faculty",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <div className="container allied-fac">
        <h3 className="submenu-heading-text fw-300 mt-1">Allied Faculty</h3>
          <div className="row mt-5">
            {AlliedFacultyData &&
              AlliedFacultyData.map((faculty) => (
                <div className="col-md-3 mb-20" key={faculty.id}>
                  <div className="core-card">
                    <div className="card-container card-ress">
                      <div className="content-3">
                        <img
                          src={faculty.image}
                          alt={faculty.name}
                          className="core-faculty"
                          style={{ cursor: "pointer" }}
                        />
                        <h6 className="main-color fw-500 core-texts">
                          {faculty.name}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default AlliedFaculty;
