import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { HomePageActions } from "../../Store/Home/action";
const Modal = lazy(() => import("react-bootstrap/Modal"));
const CloseButton = lazy(() => import("react-bootstrap/CloseButton"));

function NewsandEvents() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const dispatch = useDispatch();
  const newsRefs = useRef([]);

  const selectHomePageState = (state) => state.HomePageReducer;

  const selectNewsAndEvents = createSelector(
    selectHomePageState,
    (homePageReducer) => homePageReducer.NewsAndEvents || [] 
  );
  const selectHeading = createSelector(
    selectHomePageState,
    (homePageReducer) => homePageReducer.headings?.[0]?.heading || "Default Heading"
  );

  const NewsAndEvents = useSelector(selectNewsAndEvents);
  const heading = useSelector(selectHeading);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_NEWS_AND_EVENTS });
  }, [dispatch]);

  const handleOpenModal = (news) => {
    if (news.images.length <= 1) {
      setSelectedNews(news);
      setShowModal(true);
    } else {
      navigate("/Gallery", {
        state: {
          id: news.id,
          title: news.title,
          description: news.description,
          images: news.images,
        },
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  useEffect(() => {
    const currentHeadingRef = headingRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimateHeading(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (currentHeadingRef) observer.observe(currentHeadingRef);

    return () => {
      if (currentHeadingRef) observer.unobserve(currentHeadingRef);
    };
  }, []);

  useEffect(() => {
    const currentNewsRefs = newsRefs.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = currentNewsRefs.indexOf(entry.target);
          if (entry.isIntersecting && index !== -1) {
            currentNewsRefs[index].classList.add("news-animation");
          }
        });
      },
      { threshold: 0.2 }
    );

    currentNewsRefs.forEach((ref) => ref && observer.observe(ref));

    return () =>
      currentNewsRefs.forEach((ref) => ref && observer.unobserve(ref));
  }, [NewsAndEvents]);

  const handleViewMoreClick = () => navigate("/newsandevents");

  return (
    <div>
      <section className="services-boxs section-padding">
        <div className="container">
          <div className="sec-head mb-20">
            <div className="pt-20 d-flex align-items-center">
              <h2
                ref={headingRef}
                className={`fw-600 ls1 ${
                  animateHeading ? "heading-animation" : ""
                } portfolio-text home-heading-text`}
              
              >
                {heading}
              </h2>
            </div>
          </div>
          <div className="row pt-30">
          {Array.isArray(NewsAndEvents) && NewsAndEvents.slice(0, 4).map((news, index) => (
  <div
    key={index}
    ref={(el) => (newsRefs.current[index] = el)}
    className="col-lg-3 col-md-6 items d-rotate animated"
    onClick={() => handleOpenModal(news)}
  >
    <div className="item-box bg md-mb50 rotate-text res-text">
      <div className="icon">
        <img
          src={news.thumbnail}
          alt={`Thumbnail ${index}`}
          className="news-thumbnail"
          loading="lazy"
        />
      </div>
      <h5 className="mb-15 news-bot-wrap">
        <button
          className="news-title-newsevents"
          onClick={() => handleOpenModal(news)}
        >
          {news.title}
        </button>
      </h5>
    </div>
  </div>
))}

          </div>
          <div className="flr mt-30">
            <button
              className="go-more go-more-css"
              onClick={handleViewMoreClick}
            >
              <span className="text">View more</span>
              <span className="icon ti-arrow-circle-right news-texts"></span>
            </button>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="custom-modal"
          >
            <CloseButton className="close-button" onClick={handleCloseModal} />
            <Modal.Header>
              <Modal.Title className="animate-charcter">
                {selectedNews && selectedNews.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedNews && (
                <>
                  {selectedNews.images.length > 0 && (
                    <div className="icon">
                      <img
                        className="image-newsandevents"
                        src={selectedNews.images[0].image}
                        alt={selectedNews.images[0].content}
                        loading="lazy"
                      />
                      <p className="image-overlay">
                        {selectedNews.images[0].content}
                      </p>
                    </div>
                  )}
                  <p>{selectedNews.description}</p>
                </>
              )}
            </Modal.Body>
          </Modal>
        </Suspense>
      </section>
    </div>
  );
}

export default NewsandEvents;
