import React, { useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Syllabus() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/syllabus": { menuName: "Pedagogy", subMenuName: "Syllabus" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // useEffect(() => {
  //   dispatch({ type: SyllabusActions.GET_SYLLABUS });
  // }, [dispatch]);

  const { SyllabusData } = useSelector((state) => state?.SyllabusReducer);

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />

      <div class="section-padding">
        <div class="container">
          <table class="rwd-table">
            <tbody>
              <tr className="sylb">
                <th colspan="3" class="fz-20">
                  Syllabus
                </th>
              </tr>
              {SyllabusData?.syllabus?.map((item) => (
                <tr key={item.id}>
                  <td data-th="" className="border-gray syllabuss">
                    {item.syllabus}
                  </td>
                  <td className="syllabus-line"></td>
                  <td data-th="">
                    <a
                      href={item.pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview{" "}
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Syllabus;
