export const HomePageActions = {
  GET_HOME_TOPBAR_SCROLL_TEXT: "GET_HOME_TOPBAR_SCROLL_TEXT",
  SET_HOME_TOPBAR_SCROLL_TEXT: "SET_HOME_TOPBAR_SCROLL_TEXT",

  GET_HOME_MENUS_AND_SUBMENUS: "GET_HOME_MENUS_AND_SUBMENUS",
  SET_HOME_MENUS_AND_SUBMENUS: "SET_HOME_MENUS_AND_SUBMENUS",

  GET_HOME_HEADER_BANNER: "GET_HOME_HEADER_BANNER",
  SET_HOME_HEADER_BANNER: "SET_HOME_HEADER_BANNER",

  GET_HOME_BACKGROUNDIMAGE: "GET_HOME_BACKGROUNDIMAGE",
  SET_HOME_BACKGROUNDIMAGE: "SET_HOME_BACKGROUNDIMAGE",

  GET_HOME_TESTIMONIAL: "GET_HOME_TESTIMONIAL",
  SET_HOME_TESTIMONIAL: "SET_HOME_TESTIMONIAL",

  GET_HOME_ABOUTPRINCIPAL: "GET_HOME_ABOUTPRINCIPAL",
  SET_HOME_ABOUTPRINCIPAL: "SET_HOME_ABOUTPRINCIPAL",

  GET_HOME_LATESTVIDEO: "GET_HOME_LATESTVIDEO",
  SET_HOME_LATESTVIDEO: "SET_HOME_LATESTVIDEO",

  GET_HOME_BLOG: " GET_HOME_BLOG",
  SET_HOME_BLOG: " SET_HOME_BLOG",

  GET_HOME_STUDENTPORTFOLIO: " GET_HOME_STUDENTPORTFOLIO",
  SET_HOME_STUDENTPORTFOLIO: " SET_HOME_STUDENTPORTFOLIO",

  GET_HOME_NEWS_AND_EVENTS: "GET_HOME_NEWS_AND_EVENTS",
  SET_HOME_NEWS_AND_EVENTS: "SET_HOME_NEWS_AND_EVENTS",

  NEWSANDEVENTS_DETAILPAGE: "NEWSANDEVENTS_DETAILPAGE",
  SET_NEWSANDEVENTS_DETAILPAGE: "SET_NEWSANDEVENTS_DETAILPAGE",

  STUDENT_PORTFOLIO_DETAILPAGE: "STUDENT_PORTFOLIO_DETAILPAGE",
  SET_STUDENT_PORTFOLIO_DETAILPAGE: "SET_STUDENT_PORTFOLIO_DETAILPAGE",

  GET_LISTPAGE_BACKGROUND_IMAGE :"GET_LISTPAGE_BACKGROUND_IMAGE",
  SET_LISTPAGE_BACKGROUND_IMAGE :"SET_LISTPAGE_BACKGROUND_IMAGE",


  GET_DETAILPAGE: "GET_DETAILPAGE", 
  SET_DETAILPAGE: "SET_DETAILPAGE", 

  GET_SEO_IMAGE:"GET_SEO_IMAGE",
  SET_SEO_IMAGE:"SET_SEO_IMAGE",

};
