import React, { useEffect } from "react";
import $ from "jquery";

function Cursor() {
  useEffect(() => {
    const animateit = function (e) {
      if (!e.nativeEvent) return; // Check if e.nativeEvent exists

      const hoverAnim = e.currentTarget.querySelector(".hover-anim");
      const { offsetX: x = 0, offsetY: y = 0 } = e.nativeEvent, // Default values for offsetX and offsetY
        { offsetWidth: width = 1, offsetHeight: height = 1 } = e.currentTarget,
        move = 25,
        xMove = (x / width) * (move * 2) - move,
        yMove = (y / height) * (move * 2) - move;
      hoverAnim.style.transform = `translate(${xMove}px, ${yMove}px)`;
      if (e.type === "mouseleave") hoverAnim.style.transform = "";
    };

    const editCursor = (e) => {
      const cursor = document.querySelector(".cursor");
      cursor.style.left = e.clientX + "px";
      cursor.style.top = e.clientY + "px";
    };

    const handleHover = (e) => {
      const cursor = document.querySelector(".cursor");
      cursor.classList.toggle("cursor-active", e.type === "mouseenter");
    };

    const handleTextAnimation = () => {
      const elements = document.querySelectorAll(".rolling-text");
      elements.forEach((element) => {
        element.classList.remove("play");
      });
    };

    document.querySelectorAll(".hover-this").forEach((b) => {
      b.addEventListener("mousemove", animateit);
      b.addEventListener("mouseleave", animateit);
      b.addEventListener("mouseenter", handleHover);
    });

    window.addEventListener("mousemove", editCursor);
    $("a, .cursor-pointer").hover(handleHover);

    handleTextAnimation();

    return () => {
      document.querySelectorAll(".hover-this").forEach((b) => {
        b.removeEventListener("mousemove", animateit);
        b.removeEventListener("mouseleave", animateit);
        b.removeEventListener("mouseenter", handleHover);
      });
      window.removeEventListener("mousemove", editCursor);
    };
  }, []);

  useEffect(() => {
    var progressPath = document.querySelector(".progress-wrap path");
    var pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition =
      "none";
    progressPath.style.strokeDasharray = pathLength + " " + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition =
      "stroke-dashoffset 10ms linear";
    var updateProgress = function () {
      var scroll = $(window).scrollTop();
      var height = $(document).height() - $(window).height();
      var progress = pathLength - (scroll * pathLength) / height;
      progressPath.style.strokeDashoffset = progress;
    };
    updateProgress();
    $(window).scroll(updateProgress);
    var offset = 150;
    var duration = 550;
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > offset) {
        $(".progress-wrap").addClass("active-progress");
      } else {
        $(".progress-wrap").removeClass("active-progress");
      }
    });
    $(".progress-wrap").on("click", function (event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, duration);
      return false;
    });
  }, []);

  return (
    <div>
      <div className="cursor"></div>
      <div className="progress-wrap cursor-pointer">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            className="progress-path"
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          />
        </svg>
      </div>
    </div>
  );
}

export default Cursor;
