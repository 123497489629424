import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Principal from "../components/Submenu/Principal/Principal";
import Footer from "../components/Layout/Footer";
// import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { PrincipalActions } from "../Store/Principal/action";
import gsap from 'gsap';
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";

function Principalll() {
  document.title = "The Principal";
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: PrincipalActions.GET_ABOUTPRINCIPAL });
  }, [dispatch]);

  const { Principalinfo } = useSelector((state) => state?.PrincipalReducer);

  useEffect(() => {
    if (Principalinfo?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [Principalinfo])

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
          {/* <Cursor /> */}
          <Header />
          <Principal />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Principalll;
