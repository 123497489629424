import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";


function MarqueeText() {
  const dispatch = useDispatch();

  const { HeaderScrollText } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_TOPBAR_SCROLL_TEXT });
  }, [dispatch]);

  const marqueeRef = useRef(null);

  const handleMouseEnter = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = "paused";
    }
  };

  const handleMouseLeave = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = "running";
    }
  };

  return (
    <div>
      <div className="text-wrapper-marque col-md-9">
        <div
          ref={marqueeRef}
          className="marquee-scroll"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="marquee-content">
            {HeaderScrollText.map((item, index) => (
              <span key={index} className="marquee-scroll-item">
                <span style={{ marginRight: "5px" }}>{item.title}</span>{" "}
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.link_text}
                </a>
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="code-wrap col-md-3 text-right">
  <p className="static-text">
    <a
      href="/" 
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      Auresca
    </a>{" "}
    | TNEA Counselling code: <span>2379</span>
  </p>
</div>

    </div>
  );
}

export default MarqueeText;