import { ClubsActions } from "./action"

const initialState={
    ClubsData:[],
    ClubsDataDetail:[],
}
export const ClubsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ClubsActions.SET_CLUBS:
        return {
          ...state,
          ClubsData: action.payload,
        };
  
      case ClubsActions.SET_CLUBS_DETAIL:
        console.log("Updating ClubsDataDetail with:", action.payload);
        return {
          ...state,
          ClubsDataDetail: action.payload,
        };
  
      default:
        return state;
    }
  };
  