import { ExpertPanelMembersActions } from "./action";

const initialState = {
  // ChiefAdvisorandMemberofGoverningCouncil: [],
  MentorsCumExpertPanel: [],
};

export const ExpertPanelMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ExpertPanelMembersActions.SET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR:
    //   return {
    //     ...state,
    //     ChiefAdvisorandMemberofGoverningCouncil: action.payload,
    //   };
    case ExpertPanelMembersActions.SET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL:
      return {
        ...state,
        MentorsCumExpertPanel: action.payload,
      };

    default:
      return state;
  }
};
