import { InfrastructureActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const InfrastructureSagas = function* () {
  yield all([
    takeEvery(InfrastructureActions.GET_INFRASTRUCTURE, getInfrastructure),
    takeEvery(
      InfrastructureActions.GET_INFRASTRUCTURE_DETAIL,
      getInfrastructureDetail
    ),
  ]);
};

const getInfrastructure = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/getAllDatainfrastructureFronts`)
    );

    yield put({
      type: InfrastructureActions.SET_INFRASTRUCTURE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getInfrastructureDetail = function* (action) {
  try {
    const { id } = action.payload;

    const result = yield call(() =>
      axios.get(`${API_URL}/getAllSubfolders/${id}`)
    );

    // console.log("API Response for ID:", id, result.data);

    yield put({
      type: InfrastructureActions.SET_INFRASTRUCTURE_DETAIL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching infrastructure details:", error);
  }
};
