import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useDispatch, useSelector } from "react-redux";
import { ClubsActions } from "../../../Store/Clubs/action";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Clubs() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [activeTab, setActiveTab] = useState(""); // Active tab ID
  const [tabs, setTabs] = useState([]); // Store tab data
  const dispatch = useDispatch();

  // Fetch club data on mount
  useEffect(() => {
    dispatch({ type: ClubsActions.GET_CLUBS });
  }, [dispatch]);

  const { ClubsData, ClubsDataDetail } = useSelector((state) => state.ClubsReducer);

  // Update tabs when `ClubsData` changes
  useEffect(() => {
    if (ClubsData?.data) {
      const apiTabs = ClubsData.data.map((item, index) => ({
        id: `tabs-${index + 1}`,
        name: item.title,
        itemId: item.id,
      }));
      setTabs(apiTabs);

      if (apiTabs.length > 0) {
        setActiveTab(apiTabs[0]?.id || ""); // Set the first tab as active
        dispatch({
          type: ClubsActions.GET_CLUBS_DETAIL,
          payload: { id: apiTabs[0].itemId },
        });
      }
    }
  }, [ClubsData, dispatch]);

  const location = useLocation();
  const routeNames = useMemo(() => ({ "/clubs": { menuName: "Clubs" } }), []);
  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  // Handle tab click and fetch details for the clicked tab
  const handleTabClick = (tabId, itemId) => {
    setActiveTab(tabId);
    dispatch({
      type: ClubsActions.GET_CLUBS_DETAIL,
      payload: { id: itemId },
    });
  };

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]');
  }, [ClubsDataDetail]);

  return (
    <div>
      <HeaderBackground menuName={menuName} handleMenuToggle={handleMenuToggle} />
      <section className="services-tab section-padding mb-80" style={{ marginTop: "-25px" }}>
        <div className="container">
          <div className="bord d-flex align-items-center">
            <h4 className="fw-300">Clubs</h4>
          </div>
          <div className="row lg-marg" id="tabs">
            {/* Tabs */}
            <div className="col-lg-4">
              <div className="serv-tab-link tab-links full-width pt-40">
                <ul className="rest">
                  {tabs.map((tab) => (
                    <li
                      key={tab.id}
                      className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                        activeTab === tab.id ? "current" : ""
                      }`}
                      onClick={() => handleTabClick(tab.id, tab.itemId)}
                    >
                      <div className="u-line linee">
                        <span className="icon ti-arrow-circle-right"></span>
                        <span className="text fz-18 psg-margin">{tab.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Club Details */}
            <div className="col-lg-8 col-12">
              <div className="md-mb80">
                {ClubsDataDetail?.clubs?.length > 0 ? (
                  <div className="row">
                    {ClubsDataDetail.clubs.map((club, index) =>
                      Object.keys(club)
                        .filter((key) => key.startsWith("image")) // Filter image keys
                        .map((imageKey, imgIndex) => (
                          <div key={`${index}-${imgIndex}`} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="archi-card-container mt-30">
                              <a
                                href={club[imageKey]} // Fancybox data
                                data-fancybox="gallery"
                                // data-caption={club.description || `Image ${imgIndex + 1}`}
                              >
                                <img
                                  src={club[imageKey]}
                                  alt={club.description || "Club Image"}
                                  className="archi-card-image-lifeatcampus infrastructure-img mb-2"
                                  style={{width:"250px"}}
                                  loading="lazy"
                                />
                              </a>
                              {/* <p className="mt-2 text-center infra-subfolder-title">{club.description}</p> */}
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                ) : (
                  <p className="text-center">Loading</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default React.memo(Clubs);
