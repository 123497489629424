
import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

import { ExtraCurricularActions } from "../ExtraCurricular/action";

export const ExtraCurricularSagas = function* () {
  console.log("Sagas running extracurricular...");
  yield all([
    takeEvery(ExtraCurricularActions.GET_EXTRACURRICULAR, getLifeAtCampus),
  ]);
};

const getLifeAtCampus = function* (data) {
  try {
  
    const result = yield call(() => axios.get(`${API_URL}/ExtraCurricularIndex`));
  console.log("extracurricularsagas",result.data);
  
    yield put({
      type: ExtraCurricularActions.SET_EXTRACURRICULAR,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error in API Call:", error);
  }
};



