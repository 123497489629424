import React, { useState } from "react";

function ReportsFooter() {
  const [activeTab, setActiveTab] = useState("tabs-quarterly");

  // Handles switching between tabs
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // PDF Links for Quarterly Reports
//   const quarterlyReports = [
//     {
//       name: "PSGIAP Quarterly Report Jan'24 - March'24",
//       link: "/path-to-pdf/quarterly-report-jan-march-2024.pdf",
//     },
//     {
//       name: "PSGIAP Quarterly Report April'24 - June'24",
//       link: "/path-to-pdf/quarterly-report-april-june-2024.pdf",
//     },
//     {
//       name: "PSGIAP Quarterly Report July'24 - Sept'24",
//       link: "/path-to-pdf/quarterly-report-july-sept-2024.pdf",
//     },
//   ];
  // PDF Links for Quarterly Reports
  const AnnualReports = [
    {
      name: "Annual Report 2022-2023",
      link: "/path-to-pdf/quarterly-report-jan-march-2024.pdf",
    },
    {
      name: "Annual Report 2021-2022",
      link: "/path-to-pdf/quarterly-report-april-june-2024.pdf",
    },
    {
      name: "Annual Report 2020-2021",
      link: "/path-to-pdf/quarterly-report-july-sept-2024.pdf",
    },
  ];
  // Tab-specific content
  const tabContent = {
    "tabs-quarterly": (
      <div>
       
        <div class="tab-pane fade active show" id="quar" role="tabpanel">
              <h4>Quarterly Reports</h4>
              <ul class="nav nav-tabs" id="anna-inner" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active show" data-toggle="tab" href="#20241">2024</a>
                  </li>

                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#20231">2023</a>
                  </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#20221">2022</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#20211">2021</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#20201">2020</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#20191">2019</a>
                </li>
             

              </ul>
              <div class="tab-content" id="anna-inner">


                <div class="tab-pane fade active show" id="20241">
                    <h5 style={{color:" rgb(58, 65, 151)"}} class="mt-2">Quarterly Report - 2024</h5>

                    <table class="table table-bordered custom-table">
                        <tbody>
                          <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2024/Quarterly Report Jan to March 2024.pdf" >January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>


                             <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2024/Quarterly Report Apr to June 2024 .pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>

                            <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2024/Quarterly Report July to Sep 2024.pdf">July - September <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>

                            

                        </tbody>
                      </table>

                  </div>


                <div class="tab-pane fade" id="20231">
                    <h5 style={{color:"brown"}} class="mt-2">Quarterly Report - 2023</h5>

                    <table  class="table table-bordered custom-table">
                        <tbody>
                          <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2023/Quarterly Report Jan-Mar 2022.pdf">January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>
                            <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2023/Quarterly Report April - June 2023.pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>

                            <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2023/Quarterly Report July to September 2023.pdf" >July - September <i class="fa fa-external-link" aria-hidden="true"style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>
                             <tr>
                            <td><a target="_blank" href="uploads/quarterlyreport/2023/Quarterly Report Oct-Dec 2023.pdf" >October - December <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                            </td></tr>
                        </tbody>
                      </table>

                  </div>



                <div class="tab-pane fade" id="20221">
                    <h5 style={{color:"brown"}} class="mt-2">Quarterly Report - 2022</h5>

                  <table  class="table table-bordered custom-table">
                    <tbody>
                      <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2022/Quarterly Report Jan-Mar 2022.pdf" >January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2022/Quarterly report apr-june 22.pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>

                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2022/Quarterly Report July to Sep 2022 rev.pdf" >July - September <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                         <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2022/Quarterly report Oct to Dec 2022.pdf" >October - December <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                    </tbody>
                  </table>




                </div>
                  <div class="tab-pane fade" id="20211">
                    <h5 style={{color:"brown"}} class="mt-2">Quarterly Report - 2021</h5>


                  <table  class="table table-bordered custom-table">
                    <tbody>
                      <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2021/Quarterly Report January -March 2021.pdf" >January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2021/Quarterly Report Apr-June 2021.pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>

                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2021/Quarterly Report July-Sep 2021.pdf" >July - September <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                         <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2021/Quarterly Report oct-dec 2021.pdf" >October - December <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                    </tbody>
                  </table>



                </div>
                  <div class="tab-pane fade" id="20201">
                    <h5 style={{color:"brown"}} class="mt-2">Quarterly Report - 2020</h5>

                  <table  class="table table-bordered custom-table">
                    <tbody>
                      <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2020/Quarterly Report Jan-Mar 2020.pdf" >January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2020/Quarterly Report Apr-June 2020.pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>

                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2020/Quarterly Report July-Sep 2020.pdf" >July - September <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                         <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2020/Quarterly Report Oct_Dec 2020.pdf" >October - December <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="20191">
                    <h5 style={{color:"brown"}} class="mt-2">Quarterly Report - 2019</h5>

                  <table  class="table table-bordered custom-table">
                    <tbody>
                      <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2019/Quarterly Report jan-mar 2019.pdf" >January - March <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2019/Quartely Report Apr-June 2019.pdf" >April - June <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>

                        <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2019/July  to Sep 2019 Quarterly Report.pdf" >July - September <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                         <tr>
                        <td><a target="_blank" href="uploads/quarterlyreport/2019/Quarterly Report OCT-DEC 2019.pdf" >October - December <i class="fa fa-external-link" aria-hidden="true" style={{fontSize:"16px" , color: "brown"}}></i></a>
                        </td></tr>
                    </tbody>
                  </table>
                </div>
               
              

              </div>
            </div>
      </div>
    ),
    "tabs-annual": (
      <div>
        <h4>Annual Reports</h4>  
        <ul style={{ paddingLeft: 0, listStyle: "none"     ,
                  textDecoration: "none",
                  color: " rgb(58, 65, 151)",
                  cursor: "pointer",
                  marginTop:"20px"
              }}>
          {AnnualReports.map((report, index) => (
            <li key={index} style={{ marginBottom: "10px" }}>
              <a
                href={report.link}
                target="_blank"
                rel="noopener noreferrer"
           
              >
                {report.name} (PDF)
              </a>
            </li>
          ))}
        </ul>
      </div>
    ),
  };

  return (
    <div>
      <section className="services-tab section-padding mb-80">
        <div className="container">
          <div className="cal-action pb-10 mb-40">
            <div className="bord d-flex align-items-center">
              <h4 className="fw-300">Reports</h4>
            </div>
          </div>
          <div className="row lg-marg" id="tabs">
            {/* Tab Navigation */}
            <div className="col-lg-4" style={{ marginTop: "-50px" }}>
              <div className="serv-tab-link tab-links full-width pt-40">
                <ul className="rest">
                  <li
                    className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                      activeTab === "tabs-quarterly" ? "current" : ""
                    }`}
                    onClick={() => handleTabClick("tabs-quarterly")}
                  >
                    <div className="u-line linee">
                      <span className="icon ti-arrow-circle-right"></span>
                      <span className="text fz-18 psg-margin">
                        Quarterly Reports
                      </span>
                    </div>
                  </li>
                  <li
                    className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                      activeTab === "tabs-annual" ? "current" : ""
                    }`}
                    onClick={() => handleTabClick("tabs-annual")}
                  >
                    <div className="u-line linee">
                      <span className="icon ti-arrow-circle-right"></span>
                      <span className="text fz-18 psg-margin">
                        Annual Reports
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-8 col-12">
              <div className="md-mb80 " >{tabContent[activeTab]}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReportsFooter;
