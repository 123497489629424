import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./assets/css/plugins/pe-icon-7-stroke.css";
import "./assets/css/plugins/fontawesome-all.min.css";
import "./assets/css/plugins/themify-icons.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/css/plugins.css";
import GlobalStyle from "./common/GlobalStyle";
import AnimatedCursor from "react-animated-cursor";
// import "./assets/css/plugins/magnific-popup.css";
// import "./assets/css/plugins/swiper.min.css";
// import "./assets/css/base.css";
// import "./assets/css/plugins/YouTubePopUp.css";
// import Cursor from "./common/Cursor";
// import "./assets/css/plugins/justifiedGallery.min.css";

//PAGES
import Home from "./Pages/Home";
import GalleryPage from "./Pages/Gallery";
import StudentProjectListPage from "./Pages/StudentProjects";
import ProjectListsPage from "./Pages/ProjectListsPage";
import PsgIap from "./Pages/PsgIap";
import Trust from "./Pages/Trust";
import Adminstratio from "./Pages/Adminstration";
import StatutoryCommittee from "./Pages/StatutoryCommittee";
import Factsandfigures from "./Pages/Factsandfigures";
import CoreFaculty from "./Pages/Corefaculty";
import Visitingfaculty from "./Pages/Visitingfaculty";
import Alliedfaculty from "./Pages/Alliedfaculty";
import Administrativestaff from "./Pages/Administrativestaff";
import Principalll from "./Pages/Principalll";
import Expertpanelmembers from "./Pages/Expertpanelmembers";
import Designchair from "./Pages/Designchair";
import Syllabus from "./Pages/Syllabus";
import Academiccalendar from "./Pages/Academiccalendar";
import Academictimetable from "./Pages/Academictimetable";
import Programs from "./Pages/Programs";
import MonthlyLectureSeriess from "./Pages/MonthlyLectureSeriess";
import Nasaindex from "./Pages/Nasaindex";
import StudyTourindex from "./Pages/StudyTour";
import SiteVistsFieldEventsIndex from "./Pages/SiteVistsFieldEventsIndex";
import ConnectWithusIndex from "./Pages/ConnectWithus";
import Iqacindex from "./Pages/Iqacindex";
import AdmissionMainmenu from "./Pages/AdmissionMainmenu";
import Workindex from "./Pages/Workindex";
import ListPageNewsandEventsIndex from "./Pages/ListPageNewsandEvents";
import CurricularIndex from "./Pages/CurricularIndex";
import CoCurricularIndex from "./Pages/CoCurricularIndex";
import ExtraCurricularIndex from "./Pages/ExtraCurricularIndex";
import CollaborationIndex from "./Pages/CollaborationIndex";
import EditorialIndex from "./Pages/EditorialIndex";
import ExamcellIndex from "./Pages/ExamcellIndex";
import StatutoryDetail from "./Pages/StatutoryDetail";
import ComputerCenterIndex from "./Pages/ComputerCenterIndex";
import LectureHallIndex from "./Pages/LectureHallIndex";

import InfrastructureDetail from "./components/Submenu/Infrastructure/LabsDetailPage";
import InfrastructureIndex from "./Pages/InfrastructureIndex";
import Clubs from "./components/Submenu/Clubs/Clubs";
import ClubsIndex from "./Pages/ClubsIndex";
import SymposiumIndex from "./Pages/SymposiumIndex";
import ArchiveIndex from "./Pages/ArchiveIndex";
import ReportsFooter from "./components/Submenu/ReportsFooter/ReportsFooter";
import AnnualReports from "./Pages/AnnualReports";

function App() {
  return (
    <BrowserRouter>
      <div className="crev-agency">
        <div className="App">
          <AnimatedCursor
            color="128, 128, 128"
            innerSize={0}
            // outerSize={8}
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={6}
            animationDuration={0.01}
            showSystemCursor={true}
          />

          {/* <Cursor /> */}
          <GlobalStyle />
          <div id="smooth-content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/Gallery" element={<GalleryPage />} />
              <Route
                exact
                path="/StudentsProjectLists"
                element={<ProjectListsPage />}
              />
              <Route
                exact
                path="/ProjectDetail"
                element={<StudentProjectListPage />}
              />
              <Route exact path="/about-us/psg-iap" element={<PsgIap />} />
              <Route exact path="/about-us/the-trust" element={<Trust />} />
              <Route
                exact
                path="/about-us/administration"
                element={<Adminstratio />}
              />
              <Route
                exact
                path="/about-us/statutory-committee"
                element={<StatutoryCommittee />}
              />
              <Route path="/detailpage" element={<StatutoryDetail />} />
              <Route
                exact
                path="/about-us/facts-and-figures"
                element={<Factsandfigures />}
              />
              <Route
                exact
                path="/people/core-faculty"
                element={<CoreFaculty />}
              />
              <Route
                exact
                path="/people/visiting-faculty"
                element={<Visitingfaculty />}
              />
              <Route
                exact
                path="/people/allied-faculty"
                element={<Alliedfaculty />}
              />
              <Route
                exact
                path="/people/administrative-staffs"
                element={<Administrativestaff />}
              />
              <Route exact path="/people/principal" element={<Principalll />} />
              <Route
                exact
                path="/people/expert-panel-members"
                element={<Expertpanelmembers />}
              />
              <Route
                exact
                path="/people/design-chair"
                element={<Designchair />}
              />
              <Route exact path="/programs" element={<Programs />} />
              <Route
                exact
                path="/academic-time-table"
                element={<Academictimetable />}
              />
              <Route
                exact
                path="/academic-calendar"
                element={<Academiccalendar />}
              />
              <Route exact path="/syllabus" element={<Syllabus />} />

              <Route
                exact
                path="/monthly-lecture-series"
                element={<MonthlyLectureSeriess />}
              />
              <Route exact path="/nasa" element={<Nasaindex />} />

              <Route exact path="/study-tour" element={<StudyTourindex />} />
              <Route
                exact
                path="/site-visits-field-visits"
                element={<SiteVistsFieldEventsIndex />}
              />
              <Route
                exact
                path="/ConnectWithus"
                element={<ConnectWithusIndex />}
              />
              <Route exact path="/iqac" element={<Iqacindex />} />

              <Route exact path="/admissions" element={<AdmissionMainmenu />} />
              <Route exact path="/work-with-us" element={<Workindex />} />
              <Route
                exact
                path="/newsandevents"
                element={<ListPageNewsandEventsIndex />}
              />
              <Route exact path="/curricular" element={<CurricularIndex />} />

              <Route
                exact
                path="/co-curricular"
                element={<CoCurricularIndex />}
              />

              <Route
                exact
                path="/extra-curricular"
                element={<ExtraCurricularIndex />}
              />

              <Route
                exact
                path="/collaboration"
                element={<CollaborationIndex />}
              />

              <Route exact path="/editorial" element={<EditorialIndex />} />
              <Route exact path="/exam-cell" element={<ExamcellIndex />} />

              <Route
                exact
                path="/computer-center"
                element={<ComputerCenterIndex />}
              />
              <Route exact path="/symposium" element={<SymposiumIndex />} />

              <Route
                exact
                path="/lecture-hall"
                element={<LectureHallIndex />}
              />

              <Route
                exact
                path="/infrastructure"
                element={<InfrastructureIndex />}
              />
              <Route
                exact
                path="/labs-detail"
                element={<InfrastructureDetail />}
              />
              <Route exact path="/clubs" element={<ClubsIndex />} />
              <Route exact path="/archive" element={<ArchiveIndex/>} />
              <Route exact path="/reports" element={<AnnualReports/>}/>
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
