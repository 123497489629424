import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Gallery from "../components/News&Events/DetailPageNews&Events/index";
import Footer from "../components/Layout/Footer";
import Loading from "../common/Loading";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";

function GalleryPage() {
  document.title = "Gallery";

  const textColor = "black";
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const { DetailPage } = useSelector((state) => state.HomePageReducer);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state?.id) {
      const payload = { id: state.id };
      dispatch({
        type: HomePageActions.GET_DETAILPAGE,
        payload,
      });
    }
  }, [dispatch, state?.id]);

  useEffect(() => {
    if (DetailPage) {
      setLoading(false);
      setTitle(DetailPage.title || "");
      setDescription(DetailPage.description || "");
      setImages(DetailPage.images || []);
    } else {
      setLoading(true);
      setTitle("");
      setDescription("");
      setImages([]);
    }
  }, [DetailPage]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <Gallery title={title} description={description} images={images} />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default GalleryPage;
