import React, { useState, useEffect } from "react";
import Examcell from "../components/Submenu/ExamCell/Examcell";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from "gsap";
import Loading from "../common/Loading";
import { useSelector, useDispatch } from "react-redux";
import { ExamCellActions, InfrastructureActions } from "../Store/Infrastructure/action";

function ExamcellIndex() {
  document.title = "Exam Cell";
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: InfrastructureActions.GET_INFRASTRUCTURE  });
  }, [dispatch]);

  const { ExamCelldata } = useSelector((state) => state?.ExamCellReducer);

  useEffect(() => {
    if (ExamCelldata?.length !== 0) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [ExamCelldata]);

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to(".bluee", {
        duration: 1,
        attr: { d: "M0 502S175 272 500 272s500 230 500 230V0H0Z" },
        ease: "power2.in",
      }).to(".bluee", {
        duration: 1,
        attr: { d: "M0 2S175 1 500 1s500 1 500 1V0H0Z" },
        ease: "power2.out",
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: "M0 502S175 272 500 272s500 230 500 230V0H0Z" },
          ease: "power2.in",
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);




  return (
<div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageOpeningAnimation />
      <Header />
      <Examcell />
      <Footer />
      </>
      )}
    </div>
  );
}

export default ExamcellIndex;
