import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { CorefacultysActions } from "./action";

export const CorefacultySagas = function* () {
  yield all([
    takeEvery(CorefacultysActions.GET_COREFACULTY_SECTION, getCoreFacultyInfo),
  ]);
};

const getCoreFacultyInfo = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/core-faculty`));

    yield put({
      type: CorefacultysActions.SET_COREFACULTY_SECTION,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
