import { AdmissionsActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const AdmissionsSagas = function* () {
  yield all([takeEvery(AdmissionsActions.GET_ADMISSIONS, getAdmissions),
takeEvery(AdmissionsActions.GET_ADMISSIONS_DETAIL,getAdmissionDetail)
  ]);
};

const getAdmissions = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/getAdmissionTitle`));
console.log("getAdmissionTitle",result)
    yield put({
      type: AdmissionsActions.SET_ADMISSIONS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};


const getAdmissionDetail = function* (action) {
  try {
    const { id } = action.payload;
    console.log("Fetching details for ID:", id); // Debugging the ID
    const result = yield call(() =>
      axios.get(`${API_URL}/getAdmission/${id}`)
    );
    console.log("Admission details response:", result.data);
    yield put({
      type: AdmissionsActions.SET_ADMISSIONS_DETAIL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching admission details:", error);
  }
};