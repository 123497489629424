import { CurricularActions } from "./action";

const initialState = {
 CurricularData: [],

};

export const  CurricularReducer = (state = initialState, action) => {
  switch (action.type) {
    case  CurricularActions.SET_CURRICULAR:
      return {
        ...state,
        CurricularData: action.payload,
      };
   
      

    default:
      return state;
  }
};



