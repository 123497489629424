export const InfrastructureActions = {
     GET_INFRASTRUCTURE: "GET_INFRASTRUCTURE",
     SET_INFRASTRUCTURE: "SET_INFRASTRUCTURE",

     GET_INFRASTRUCTURE_DETAIL: "GET_INFRASTRUCTURE_DETAIL",
     SET_INFRASTRUCTURE_DETAIL: "SET_INFRASTRUCTURE_DETAIL",
};



