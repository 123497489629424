import { combineReducers } from "redux";
import { HomePageReducer } from "./Home/reducer";
import { psgiaqReducer } from "./psg-iaq/reducer";
import { TheTrustReducer } from "./The Trust/reducer";
import { SonsandCharitiesReducer } from "./PsgSonsCharities/reducer";
import { FactsandFiguresReducer } from "./FactsandFigures/reducer";
import { StatutoryCommitteeReducer } from "./Statutory Committee/reducer";
import { AdministrationReducer } from "./Administration/reducer";
import { FooterReducer } from "./Footer/reducer";
import { DesignchairReducer } from "./design-chair/reducer";
import { PrincipalReducer } from "./Principal/reducer";
import { CorefacultysReducer } from "./Core Faculty/reducer";
import { VisitingFacultyReducer } from "./visiting-faculty/reducer";
import { AlliedFacultyReducer } from "./Allied Faculty/reducer";
import { ExpertPanelMembersReducer } from "./expert-panel-memebers/reducer";
import { AdministrativeStaffReducer } from "./Administrative Staffs/reducer";
import { AcademicCalendarReducer } from "./Academic Calendar/reducer";
import { AcademictimetableReducer } from "./Academic-time-table/reducer";
import { ProgramsReducer } from "./programs/reducer";
import { SyllabusReducer } from "./syllabus/reducer";
import { ConnectWithUsReducer } from "./Connect With Us/reducer";
import { LearningEventsReducer } from "./Learning Events/reducer";
import { LifeAtCampusReducer } from "./LifeAtCampus/reducer";
import { iqacReducer } from "./IQAC/reducer";
import { WorkWithUsReducer } from "./Work with us/reducer";
import { AdmissionsReducer } from "./Admissions/reducer";
import { CurricularReducer } from "./Curricular/reducer";
import { CocurricularReducer } from "./CoCurricular/reducer";
import { EditorialReducer } from "./Editorial/reducer";
import {ExamCellReducer} from "./ExamCell/reducer"
import { ExtraCurricularReducer } from "./ExtraCurricular/reducer";
import { CollaborationReducer } from "./Collaboration/reducer";
import { InfrastructureReducer } from "./Infrastructure/reducer";
import { ClubsReducer } from "./Clubs/reducer";


const rootReducer = combineReducers({
  HomePageReducer,
  psgiaq: psgiaqReducer,
  TheTrustReducer,
  SonsandCharitiesReducer,
  FactsandFiguresReducer,
  StatutoryCommitteeReducer,
  AdministrationReducer,
  FooterReducer,
  DesignchairReducer,
  PrincipalReducer,
  CorefacultysReducer,
  VisitingFacultyReducer,
  AlliedFacultyReducer,
  ExpertPanelMembersReducer,
  AdministrativeStaffReducer,
  AcademicCalendarReducer,
  AcademictimetableReducer,
  ProgramsReducer,
  SyllabusReducer,
  ConnectWithUsReducer,
  LearningEventsReducer,
  LifeAtCampusReducer,
  iqacReducer,
  WorkWithUsReducer,
  AdmissionsReducer,
  CurricularReducer,
  CocurricularReducer,
  EditorialReducer,
  ExamCellReducer,
  ExtraCurricularReducer,
  CollaborationReducer,
  InfrastructureReducer,
  ClubsReducer
});

export default rootReducer;
