import React, { useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { useNavigate } from "react-router-dom";

function Statutorycommitte() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  document.title = "Statutory Committee";

  const routeNames = {
    "/statutory-committee": {
      menuName: "About Us",
      subMenuName: "Statutory Committee",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const { StatutoryCommittee } = useSelector(
    (state) => state?.StatutoryCommitteeReducer
  );

  // Access the 'committee' array inside 'StatutoryCommittee'
  const committees = StatutoryCommittee?.committee || [];
  const committeeContent =
    StatutoryCommittee?.statutory_committee?.[0]?.committee_content || "";

  const handleCommitteeClick = (committeeName) => {
    navigate("/detailpage", { state: { committeeName } });
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="statutory">
        <div className="container statutorycommittee">
          <div className="mb-60">
          <h4 className="submenu-heading-text statutory-comm fw-300">Statutory Committee</h4>
            <div className="bord pt-25  d-flex align-items-center flex-md-wrap">
              <div className="statutorycommittee1">
                {/* <h4 className="fw-600 text-u ls1 sct">
                  Institutional{" "}
                  <span className="fw-200 committee-text">Committees</span>
                </h4> */}
              </div>
              <div className="committee-center">
                <p className="committee-center-para">
                  <div className="committee-center-para1">{committeeContent}</div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-clr">
          <div className="container">
            <div className="buttons mb-60 cards-space-res">
              {committees.map((committee) => (
                <button
                  key={committee.id}
                  className="neumorphic stat-cards"
                  onClick={() => handleCommitteeClick(committee.committee_name)}
                >
                  <img
                    src={committee.pdf} // Display the image if needed
                    alt={committee.committee_name || "Committee Image"} // added alt text
                    className="images-stat"
                  />
                  <span>{committee.committee_name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Statutorycommitte;
