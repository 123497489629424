export const routes = {
  "PSGIAP": "/about-us/psg-iap",
  "The Trust": "/about-us/the-trust",
  "PSG & Sons’ Charities": "https://www.psgsonscharities.org",
  Administration: "/about-us/administration",
  "Statutory Committee": "/about-us/statutory-committee",
  "Facts & Figures": "/about-us/facts-and-figures",
  Principal: "/people/principal",
  "Core Faculty": "/people/core-faculty",
  "Visiting Faculty": "/people/visiting-faculty",
  "Allied Faculty": "/people/allied-faculty",
  "Administrative Staffs": "/people/administrative-staffs",
  "Expert Panel Members": "/people/expert-panel-members",
  "Design Chair": "/people/design-chair",
  Programs: "/programs",
  "Academic Timetable": "/academic-time-table",
  "Academic Calendar": "/academic-calendar",
  Syllabus: "/syllabus",
  Admissions: "/admissions",
  // "Life At Campus": "/life-at-campus",
  "Monthly lecture series": "/monthly-lecture-series",
  "Study Tour": "/study-tour",
  "Site Visits & Field Visits": "/site-visits-field-visits",
  NASA: "/nasa",
  Symposium: "/symposium",
  Editorial: "/editorial",
  "Connect with us": "/ConnectWithus",
  IQAC: "/iqac",
  "Work with us": "/work-with-us",
  Archigazette: "/",
  ListPageNewsandEvents: "/newsandevents",
  Curricular: "/curricular",
  "Co-Curricular": "/co-curricular",
  "Extra Curricular": "/extra-curricular",
  Collaboration: "/collaboration",
"Exam Cell":"/exam-cell",
"Library":"/library",
"Labs":"/labs",
"Computer Center":"/computer-center",
"Material museum":"/material-museum",
"Lecture Hall":"/lecture-hall",
"Exhibition Hall":"/exhibition-hall",
"Seminar Hall":"/seminar-hall",
"Studio":"/studio",
"Infrastructure":"/infrastructure",
"Clubs":"/clubs",
"Archive":"/archive",
"Annual Reports":"/reports"
};




