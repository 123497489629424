import { AdmissionsActions } from "./action";


const initialState = {
    AdmissionsData: [],
    AdmissionDataDetail:[],
  };
  
  export const AdmissionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case AdmissionsActions.SET_ADMISSIONS:
        return {
          ...state,
          AdmissionsData: action.payload,
        };
          case AdmissionsActions.SET_ADMISSIONS_DETAIL:
                console.log("Updating AdmissionsDataDetail with:", action.payload);
                return {
                  ...state,
                  AdmissionDataDetail: action.payload,
                };
      default:
        return state;
    }
  };