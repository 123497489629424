import { LifeAtCampusActions } from "./action";
import { takeEvery, call, all, put,  } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const LifeAtCampusSagas = function* () {
  yield all([
    takeEvery(LifeAtCampusActions.GET_LIFE_AT_CAMPUS, getLifeAtCampus),
  ]);
};

const getLifeAtCampus = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/life-at-campus`));

    yield put({
      type: LifeAtCampusActions.SET_LIFE_AT_CAMPUS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
