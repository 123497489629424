import { CollaborationActions } from "./action";

const initialState = {
    CollaborationData: [],
};

export const CollaborationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CollaborationActions.SET_COLLABORATION:
      return {
        ...state,
        CollaborationData: action.payload,
      };
    default:
      return state;
  }
};
