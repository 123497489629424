import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FooterActions } from "../../../Store/Footer/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function ReportsFooter() {
  const [activeTab, setActiveTab] = useState(""); // Active tab ID
  const dispatch = useDispatch();
   const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tabs, setTabs] = useState([]); // Store tab data
  const [activeYear, setActiveYear] = useState(""); // Currently active year
    const [years, setYears] = useState([]); // List of years
    const [subtitle, setSubtitle] = useState(""); // Current subtitle
    const [reportLinks, setReportLinks] = useState([]); // Text and PDFs
  const { ReportsTitle, ReportsData } = useSelector(
    (state) => state.FooterReducer
  );
  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  // Fetch Report Titles on Component Load
  useEffect(() => {
    dispatch({ type: FooterActions.GET_REPORTS_TITLE });
  }, [dispatch]);

  // Populate Tabs from API Response
  useEffect(() => {
    if (ReportsTitle?.data) {
      const apiTabs = ReportsTitle.data.map((item, index) => ({
        id: `tabs-${index + 1}`,
        name: item.title,
        itemId: item.id, // ID to fetch reports for this tab
      }));
      setTabs(apiTabs);

      // Set the first tab as active and fetch its data
      if (apiTabs.length > 0) {
        const firstTab = apiTabs[0];
        setActiveTab(firstTab.id);
        dispatch({
          type: FooterActions.GET_REPORTS_DATA,
          payload: { id: firstTab.itemId },
        });
      }
    }
  }, [ReportsTitle, dispatch]);

// Update Years and Reports Data when Available
  useEffect(() => {
    if (ReportsData?.length > 0) {
      const yearsList = ReportsData.map((data) => ({
        year: data.year,
        subtitle: data.subtitle,
        text_1: data.text_1,
        pdf_1: data.pdf_1,
        text_2: data.text_2,
        pdf_2: data.pdf_2,
        text_3: data.text_3,
        pdf_3: data.pdf_3,
      }));

      setYears(yearsList.map((y) => y.year)); 
      const latestYearData = yearsList[0]; 
      setActiveYear(latestYearData.year);
      setSubtitle(latestYearData.subtitle);
      setReportLinks([
        { text: latestYearData.text_1, pdf: latestYearData.pdf_1 },
        { text: latestYearData.text_2, pdf: latestYearData.pdf_2 },
        { text: latestYearData.text_3, pdf: latestYearData.pdf_3 },
      ]);
    }
  }, [ReportsData]);

  // Handle Year Click
  const handleYearClick = (year) => {
    setActiveYear(year);
    const selectedYearData = ReportsData.find((data) => data.year === year);
    if (selectedYearData) {
      setSubtitle(selectedYearData.subtitle);
      setReportLinks([
        { text: selectedYearData.text_1, pdf: selectedYearData.pdf_1 },
        { text: selectedYearData.text_2, pdf: selectedYearData.pdf_2 },
        { text: selectedYearData.text_3, pdf: selectedYearData.pdf_3 },
      ]);
    }
  };

  // Handle Tab Click
  const handleTabClick = (tabId, itemId) => {
    setActiveTab(tabId); // Set active tab
    dispatch({
      type: FooterActions.GET_REPORTS_DATA,
      payload: { id: itemId }, // Fetch data for the clicked tab
    });
  };

  return (
    <div>
      <HeaderBackground
      menuName="Footer" handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80">
        <div className="container">
          <div className="cal-action pb-10 mb-40">
            <div className="bord d-flex align-items-center">
              <h4 className="fw-300">Reports</h4>
            </div>
          </div>
          <div className="col-lg-12 small-screen-titles">
                      <ul className="rest">
                      {tabs.map((tab,index) => (
                          <li
                            key={tab.id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === tab.id ? "current" : ""
                            }`}
                            onClick={() =>  handleTabClick(tab.id, tab.itemId)}
                          >
                            <h5>
                              <span className="fz-18 opacity-7 mr-15">{`0${
                                index} `}</span>
                               {tab.name}
                            </h5>
                          </li>
                        ))}
                      </ul>
                    </div>

          <div className="row lg-marg" id="tabs">
            {/* Tab Navigation */}
            <div className="col-lg-4 large-screen-titles" style={{ marginTop: "-50px" }}>
              <div className="serv-tab-link tab-links full-width pt-40">
                <ul className="rest">
                  {tabs.map((tab) => (
                    <li
                      key={tab.id}
                      className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                        activeTab === tab.id ? "current" : ""
                      }`}
                      onClick={() => handleTabClick(tab.id, tab.itemId)}
                    >
                      <div className="u-line linee">
                        <span className="icon ti-arrow-circle-right"></span>
                        <span className="text fz-18 psg-margin">{tab.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-8 col-12">
              <div className="md-mb80">

        
   {/* Report Subtitle */}
   <h5 style={{ color: "rgb(58, 65, 151)" }} className="mt-4">
            {subtitle}
          </h5>
          {/* Year Tabs */}
          <ul className="nav nav-tabs" id="year-tabs" role="tablist">
            {years.map((year) => (
              <li key={year} className="nav-item">
                <button
                  className={`nav-link ${activeYear === year ? "active" : ""}`}
                  onClick={() => handleYearClick(year)}
                >
                  {year}
                </button>
              </li>
            ))}
          </ul>

       

          {/* Report Links */}
          <table className="table table-bordered custom-table">
            <tbody>
              {reportLinks.map(
                (link, index) =>
                  link.text && link.pdf && (
                    <tr key={index}>
                      <td>
                        <a
                          href={link.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.text}{" "}
                          <i
                            className="fa fa-file-pdf-o"
                            aria-hidden="true"
                            style={{
                              fontSize: "16px",
                              color: "brown",
                            }}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
              </div>
            </div>
   
        </div>
      </section>
    </div>
  );
}

export default ReportsFooter;
