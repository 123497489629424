import { InfrastructureActions } from "./action";
const initialState = {
  InfrastructureData: [],
  InfrastructureDetail: [],
};
export const InfrastructureReducer = (state = initialState, action) => {
  switch (action.type) {
    case InfrastructureActions.SET_INFRASTRUCTURE:
      return {
        ...state,
        InfrastructureData: action.payload,
      };

      case InfrastructureActions.SET_INFRASTRUCTURE_DETAIL:
  // console.log("Reducer Payload:", action.payload); 
  return {
    ...state,
    InfrastructureDetail: action.payload,
  };

    default:
      return state;
  }
};
