import React, { useState, useEffect, } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {  useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
function TheTrust() {
  // const [showPopup, setShowPopup] = useState(false);
  // const [videoUrl, setVideoUrl] = useState("");
  // const modalRef = useRef(null);
  const [trusteeData, setTrusteeData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/the-trust": { menuName: "About Us", subMenuName: "The Trust" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };


  //   function handleClickOutside(event) {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       closePopup();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     if (showPopup) {
  //       e.preventDefault();
  //     }
  //   };

  //   if (showPopup) {
  //     document.body.style.overflow = "hidden";
  //     document.addEventListener("scroll", handleScroll, { passive: false });
  //   } else {
  //     document.body.style.overflow = "";
  //     document.removeEventListener("scroll", handleScroll, { passive: false });
  //   }

  //   return () => {
  //     document.body.style.overflow = "";
  //     document.removeEventListener("scroll", handleScroll, { passive: false });
  //   };
  // }, [showPopup]);

  // const openPopup = (url) => {
  //   setVideoUrl(url);
  //   setShowPopup(true);
  // };

  // const closePopup = () => {
  //   setVideoUrl("");
  //   setShowPopup(false);
  // };

  // const handleOverlayClick = (e) => {
  //   if (e.target.classList.contains("video-popup")) {
  //     closePopup();
  //   }
  // };

  useEffect(() => {
    AOS.init({
      delay: 50,
      duration: 1000,
    });
  }, []);

  // useEffect(() => {
  //   dispatch({ type: TheTrustAction.GET_THE_TRUST_INFO_MSG });
  // }, [dispatch]);

  const { TheTrustInfoandMsg } = useSelector((state) => state?.TheTrustReducer);


  useEffect(() => {
    if (TheTrustInfoandMsg?.length) {
      setTrusteeData(TheTrustInfoandMsg[0]);
    }
  }, [TheTrustInfoandMsg]);

  if (!trusteeData) return <div>Loading...</div>;

  const messageParagraphs =
    trusteeData?.message
      ?.split("\n")
      .filter((paragraph) => paragraph.trim() !== "") || [];

  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        {/* Trustee Message */}
        <section className="about sub-bg" data-scroll-index="2">
          <div className="container mt-30">
            <div className="row md-marg manage-wrap trustee-section">
              <div className="col-lg-6 trustee-message">
                <h6 className="sub-title main-color mb-15 fw-320">
                  Trustee Message
                </h6>
                <h2 className="fw-400 d-rotate wow fz-35">
                  <span className="rotate-text trustee-msg trustee-name">
                    {trusteeData?.name}
                  </span>
                  <h6 className="text-u ls1 opacity-8 rotate-text fw-400 trustee-texts">
                    {trusteeData?.position}
                  </h6>
                  <div className="info">
                    <p className="nowrap">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                          ></path>
                        </svg>
                      </span>
                      &nbsp; &nbsp; {trusteeData?.mobile}
                    </p>
                    <p className="nowrap">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                          ></path>
                        </svg>
                      </span>
                      &nbsp; &nbsp; {trusteeData?.email}
                    </p>
                  </div>
                </h2>
                <div className="d-flex align-items-end mt-30 trust-paragraph">
                  <div>
                    {messageParagraphs.slice(0, 2).map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 trustee-image-container">
                <div className="img-vid">
                  {trusteeData?.image ? (
                    <img
                      src={trusteeData.image}
                      alt="Trustee"
                      className="trustee-image"
                    />
                  ) : (
                    <p>Image not available</p>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="row md-marg manage-wrap trustee-section">
              <div className="col-lg-6 trustee-message">
                <div className="d-flex align-items-end ">
                  <div>
                    {messageParagraphs.slice(2, 3).map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 trustee-message">
                <div className="d-flex align-items-end">
                  <div>
                    {messageParagraphs.slice(3, 4).map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row md-marg manage-wrap trustee-section trust-paragraph">
              {messageParagraphs.slice(2, 3).map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}

              {messageParagraphs.slice(3, 4).map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
          <div className="container">
            {/* <div className="col-md-6">
            <div className="mt-30">
              <a href="/" className="go-more-trust read-moree">
                <span className="text readmore">Read More </span>
                <span className="icon ti-arrow-circle-right"></span>
              </a>
            </div>
          </div> */}
          </div>
        </section>
        <section className="services-details section-padding">
          <div className="container">
            <div className="sec-head text-center mb-80">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div data-aos="zoom-in-up">
                    <h4 className="italic text-zoomin">
                      "{trusteeData?.quote}"
                    </h4>
                    <div>
                      <span className="sub-title main-color mt-20">
                        {trusteeData?.author}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <div className="in-box mb-80 parallax-container1">
          <div className="img-scale1">
            <div className="image">
              <div
                className="img parallax-image1"
                style={{
                  backgroundImage: `url(${trusteeData?.bg_image || ""})`,
                }}
              />
              <div className="content"></div>
              <span
                className="icon fz-50 ti-control-play vid vid-circle d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => openPopup(trusteeData?.link)}
              ></span>
              {showPopup && (
                <div className="video-popup" onClick={handleOverlayClick}>
                  <div className="video-container-testimonial mt-40">
                    <iframe
                      title="YouTube Video"
                      width="560"
                      height="415"
                      className="video-content"
                      src={videoUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="close-btn-videosection" onClick={closePopup}>
                    <i className="fas fa-times"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default TheTrust;
