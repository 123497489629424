import React, { useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function FactsandFigures() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const routeNames = {
    "/facts-and-figures": {
      menuName: "About Us",
      subMenuName: "Facts & Figures",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  // useEffect(() => {
  //   dispatch({ type: FactsandFiguresActions.GET_FACTSANDFIGURES_SECTIONS });
  // }, [dispatch]);

  const { factsandfigures } = useSelector(
    (state) => state?.FactsandFiguresReducer
  );


  const imageUrl = factsandfigures?.[0]?.image || "";

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding factsandfigures1">
        <div className="container">
          <div className="bord  d-flex align-items-center facts-text">
          <h4 className="submenu-heading-text  facts-fig fw-300">Facts And Figures </h4>
          </div>
          <div className="facts">
            {imageUrl ? (
              <img
                className="facts-img"
                src={imageUrl}
                alt="Facts and Figures"
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default FactsandFigures;




