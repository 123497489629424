import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HeaderBackground from "../../../common/SubpagesBGimage";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { StatutoryCommitteeActions } from "../../../Store/Statutory Committee/action";

function Detailpage() {
  const location = useLocation();
  const { committeeName = "" } = location.state || {};
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const routeNames = {
    "/statutory-committee": {
      menuName: "About Us",
      subMenuName: "Statutory Committee",
    },
  };

  const { menuName = "About Us", subMenuName = "Statutory Committee" } =
    location.state || routeNames[location.pathname] || {};

  const { StatutoryCommitteeSubpage } = useSelector(
    (state) => state?.StatutoryCommitteeReducer
  );

  console.log("Full StatutoryCommitteeSubpage:", StatutoryCommitteeSubpage);

  useEffect(() => {
    dispatch({
      type: StatutoryCommitteeActions.GET_STATUTORY_COMMITTEE_SUBPAGE,
    });
  }, [dispatch]);

  console.log("committeeName from route:", committeeName);

  const normalizedCommitteeName = committeeName?.trim().toLowerCase() || "";

  const allCommitteeNames = StatutoryCommitteeSubpage?.map(
    (committee) => committee.committee_name
  );
  console.log("All committee names:", allCommitteeNames);

  const selectedCommittee = StatutoryCommitteeSubpage?.find(
    (committee) =>
      committee.committee_name.trim().toLowerCase() === normalizedCommitteeName
  );

  console.log("selectedCommittee:", selectedCommittee);

  const members = selectedCommittee?.members || [];

  console.log("members array:", members);

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
      />

      <div className="col-12 items art">
        <div className="item mt-0">
          <div className="mt-30">
            <div className="container">
              <h2 className="sub-title main-color mb-3 mt-5">
                {committeeName}
              </h2>
              <div className="row bord-thin-top">
                {members.length > 0 ? (
                  members.map((member, index) => (
                    <div
                      key={index}
                      className="col-lg-3 col-md-6 col-sm-6 col-12"
                    >
                      <div className="card-2 mt-40 mb-40">
                        <div className="card-overlay-2"></div>
                        <div className="card-inner-2">
                          {member.member_name && (
                            <h6 className="fw-700">{member.member_name}</h6>
                          )}
                          {member.designation && <p>{member.designation}</p>}
                          {member.committee && (
                            <p className="main-color">{member.committee}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="spinner-container-admission">
                  <div className="spinner-admission"></div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Detailpage;
