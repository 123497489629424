import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { FooterActions } from "../../Store/Footer/action";

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    gsap.set(".footer-container", { yPercent: -50 });
    const uncover = gsap
      .timeline({ paused: true })
      .to(".footer-container", { yPercent: 0, ease: "none" });

    ScrollTrigger.create({
      trigger: "main",
      start: "bottom bottom",
      end: "+=50%",
      animation: uncover,
      scrub: true,
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    gsap.to(".footer-container", { yPercent: -scrollY });
  };

  useEffect(() => {
    dispatch({ type: FooterActions.GET_FOOTER_DETAILS });
  }, [dispatch]);

  const { Footerdata } = useSelector((state) => state.FooterReducer);

  return (
    <div className="footer-common">
      <footer
        className="clean-footer crev bg-img"
        data-background="assets/imgs/background/3.jpg"
        data-overlay-dark="10"
      >
        <div className="container pb-0 pt-70 section-padding ontop">
          <div className="row row-footer">
            <div className="col-lg-4 col-md-12">
              <h6 className="sub-title mb-30">About Us</h6>
              <p className="opacity-8 footer-text">
                {Footerdata["About-us"] && Footerdata["About-us"][0]?.text}
              </p>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="column">
                <h6 className="sub-title mb-30 footer-subtitle1">
                  Useful Links
                </h6>
                <ul className="rest fz-14">
                  {Footerdata.links &&
                    Footerdata.links.map((link, index) => (
                      <li key={index} className="mb-15 opacity-8">
                        <a
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.pagename}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="column">
                <h6 className="sub-title mb-30 footer-subtitle">Downloads</h6>
                <ul className="rest fz-14">
                  {Footerdata.downloads &&
                    Footerdata.downloads.map((download, index) => (
                      <li key={index} className="mb-15 opacity-8">
                        <a
                          href={download.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                          // onClick={(e) => {
                          //   // Optional: You can still include the preventDefault to handle specific logic
                          //   // e.preventDefault();
                          //   // window.location.href = download.pdf;
                          // }}
                        >
                          {download.pdf_name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="column">
                {Footerdata.contact &&
                  Footerdata.contact.map((contact, index) => (
                    <div key={index} className="mb-10">
                      <h6 className="sub-title mb-30 footer-subtitle">
                        Contact
                      </h6>
                      <p className="opacity-8 footer-text">{contact.address}</p>
                      <a className="opacity-8" href={`mailto:${contact.mail}`}>
                        <span>{contact.mail}</span>
                      </a>

                      <div className="contact-numbers">
                        <a
                          className="opacity-8"
                          href={`tel:${contact.contact_no_1}`}
                        >
                          <span className="num-footer">
                            {contact.contact_no_1}
                          </span>
                        </a>
                        {" | "}
                        <a
                          className="opacity-8"
                          href={`tel:${contact.contact_no_2}`}
                        >
                          <span className="num-footer">
                            {contact.contact_no_2}
                          </span>
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
            <div>
              <p className="fz-14">
                Copyright © {new Date().getFullYear()} PSG IAP. All rights
                reserved. Design :{" "}
                <span>
                  <a
                    href="https://mindmade.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MindMade
                  </a>
                </span>
              </p>
            </div>
            <div className="ml-auto">
              <ul className="rest d-flex align-items-center" style={{gap:"20px"}}>
                {Footerdata.Social_links &&
                  Footerdata.Social_links.map((social, index) => (
                    <React.Fragment key={index}>
                      {social.facebook && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-facebook-f" />
                          </motion.a>
                        </li>
                      )}
                      {social.linkedin && (
                        <li className="hover-this cursor-pointer">
                          <motion.a
                            href={social.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-linkedin-in" />
                          </motion.a>
                        </li>
                      )}
                      {social.instagram && (
                        <li className="hover-this cursor-pointer">
                          <motion.a
                            href={social.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-instagram" />
                          </motion.a>
                        </li>
                      )}
                       {social.youtube && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-youtube" />
                          </motion.a>
                        </li>
                      )}
                      {social.twitter && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-twitter" />
                          </motion.a>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;



// import React, { useEffect, useState } from "react";
// import Logo from "../../assets/imgs/logo.webp";
// import Hmenu from "../../assets/imgs/h-menu.webp";
// import $ from "jquery";
// import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import MarqueeText from "../../common/marquee";
// import { motion } from "framer-motion";
// import { useDispatch, useSelector } from "react-redux";
// import Search from "../../common/Search";
// import { useLocation, useNavigate } from "react-router-dom";
// import { routes } from "../../routes/routes";
// import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSuitcase } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// // const { SubMenu } = Menu;

// const Header = ({ Color, isInGallery, isInProject, charityLink }) => {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [openKeys, setOpenKeys] = useState([]);
//   const navigate = useNavigate();

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const onOpenChange = (keys) => {
//     setOpenKeys(keys);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollTop = window.scrollY;
//       setIsScrolled(scrollTop > 300);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     $(".navbar .search-form").on("click", ".search-icon", function () {
//       $(".navbar .search-form").toggleClass("open");

//       if ($(".navbar .search-form").hasClass("open")) {
//         $(".search-form .close-search").slideDown();
//       } else {
//         $(".search-form .close-search").slideUp();
//       }
//     });

//     $(".navbar").on("click", ".navbar-toggler", function () {
//       $(".navbar .navbar-collapse").toggleClass("show");
//     });

//     $(window).on("scroll", function () {
//       var bodyScroll = window.scrollY,
//         navbar = $(".navbar"),
//         logo = $(".navbar.change .logo > img");

//       if (bodyScroll > 300) {
//         navbar.addClass("nav-scroll");
//         logo.attr("src", "assets/imgs/logo-dark.png");
//       } else {
//         navbar.removeClass("nav-scroll");
//         logo.attr("src", "assets/imgs/logo-light.png");
//       }
//     });

//     // Clean up jQuery events
//     return () => {
//       $(".navbar .search-form").off("click", ".search-icon");
//       $(".navbar").off("click", ".navbar-toggler");
//       $(window).off("scroll");
//     };
//   }, []);

//   const handleMenuItemClick = (event) => {
//     const submenu = event.target.nextSibling;
//     if (submenu && submenu.classList.contains("sub-menu")) {
//       submenu.classList.toggle("show");
//     }
//   };

//   const [isOpen, setIsOpen] = useState(false);

//   // Function to toggle submenu
//   const toggleSubMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const { Footerdata } = useSelector((state) => state.FooterReducer);

//   return (
//     <>
//       <nav
//         className={`navbar ${isScrolled ? "nav-scroll" : ""} ${
//           isInGallery ? "gallery-navbar" : ""
//         }`}
//       >
//         {/* Top section */}
//         <div className="top-men">
//           <div className="container-fluid">
//             <div className="row align-items-center">
//               <MarqueeText />
//             </div>
//           </div>
//         </div>

//         {/* Logo, Search Form, and Menu */}
//         <div className="container h-top logo-socialicon">
//           {/* Logo */}
//           <a className="logo " href="/">
//             {isInGallery ? (
//               <img src={Hmenu} alt="logo" className="res-logo1" />
//             ) : (
//               <img
//                 src={isScrolled ? Hmenu : Logo}
//                 alt="logo"
//                 className="res-logo"
//               />
//             )}
//           </a>

//           {/* Search Form */}
//           {/* <div className="search-form ml-auto">
//             <div className="form-group">
//               <input type="text" name="search" placeholder="Search" />
//               <button>
//                 <span className="pe-7s-search"></span>
//               </button>
//             </div>
//             <div className="search-icon" style={{ color: Color }}>
//               <span className="pe-7s-search open-search"></span>
//               <span className="pe-7s-close close-search"></span>
//             </div>
//           </div> */}

// {/*           
//           <Search
//             routes={Object.keys(routes).map((name) => ({
//               name,
//               path: routes[name],
//             }))}
//             navigate={navigate}
//             handleMenuToggle={handleMenuToggle}
//           /> */}



//           {/* Menu */}
//           <div className="topnav d-flex">


//           <li className="hover-this cursor-pointer  header-icons h-work-icon">
//   <Link 
//     to="/work-with-us" 
//     style={{
//       color: isScrolled || isInGallery ? "black" : "",
//     }} 
//     className={isScrolled || isInGallery ? "" : "white"}
//   >
//     <motion.div whileHover={{ scale: 1.6 }}>
//       <FontAwesomeIcon icon={faSuitcase} />
//     </motion.div>
//   </Link>
// </li>

// <li className="hover-this cursor-pointer header-icons h-contact-icon ">
//   <Link 
//     to="/ConnectWithus" 
//     style={{
//       color: isScrolled || isInGallery ? "black" : "",
//     }} 
//     className={isScrolled || isInGallery ? "" : "white"}
//   >
//     <motion.div whileHover={{ scale: 1.6 }}>
//       <FontAwesomeIcon icon={faAddressBook} />
//     </motion.div>
//   </Link>
// </li>

//             <div
//               className="menu-icon cursor-pointer res-menu1"
//               style={{ color: Color }}
//               onClick={handleMenuToggle}
//             >
//               <span className="icon ti-menu" style={{ color: Color }}></span>
//               Menu
//             </div>
//             <ul
//       className={`rest d-flex mt-100 align-items-center social-icons res-menu res-socc ${
//         isScrolled ? "hidden" : ""
//       }`}
//     >
//               {Footerdata?.Social_links?.map((social, index) => (
//                 <React.Fragment key={index}>
//                   {social.facebook && (
//                     <li className="hover-this cursor-pointer">
//                       <a
//                         href={social.facebook}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         style={{
//                           color: isScrolled || isInGallery ? "black" : "",
//                         }}
//                         className={isScrolled || isInGallery ? "" : "white"}
//                       >
//                         <motion.i
//                           className="fab fa-facebook-f"
//                           whileHover={{ scale: 1.6 }}
//                         />
//                       </a>
//                     </li>
//                   )}
//                   {social.linkedin && (
//                     <li className="hover-this cursor-pointer ml-30">
//                       <a
//                         href={social.linkedin}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         style={{
//                           color: isScrolled || isInGallery ? "black" : "",
//                         }}
//                         className={isScrolled || isInGallery ? "" : "white"}
//                       >
//                         <motion.i
//                           className="fab fa-linkedin-in"
//                           whileHover={{ scale: 1.6 }}
//                         />
//                       </a>
//                     </li>
//                   )}
//                   {social.instagram && (
//                     <li className="hover-this cursor-pointer ml-30">
//                       <a
//                         href={social.instagram}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         style={{
//                           color: isScrolled || isInGallery ? "black" : "",
//                         }}
//                         className={isScrolled || isInGallery ? "" : "white"}
//                       >
//                         <motion.i
//                           className="fab fa-instagram"
//                           whileHover={{ scale: 1.6 }}
//                         />
//                       </a>
//                     </li>
//                   )}
//                 </React.Fragment>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </nav>

//       <SideNavigation
//         isMenuOpen={isMenuOpen}
//         handleMenuToggle={handleMenuToggle}
//         openKeys={openKeys}
//         onOpenChange={onOpenChange}
//       />
//     </>
//   );
// };

// export default Header;