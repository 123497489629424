import React, { useState, useEffect } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import pdf from "../../../assets/imgs/dummy pdf.pdf";
import share from "../../../assets/imgs/shareicon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExamCellActions } from "../../../Store/ExamCell/action";

function Examcell() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleVisibilityCircular = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const routeNames = {
    "/exam-cell": {
      menuName: "Pedagogy",
      subMenuName: "Exam Cell",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Static sections and contents
  const sections = [
    {
      section: {
        section_id: 1,
        section_name: "About",
      },
      contentType: "about",
      content: [],
    },
    {
      section: {
        section_id: 2,
        section_name: "People",
      },
      contentType: "cards",
    },
    {
      section: {
        section_id: 3,
        section_name: "Circulars",
      },
      contentType: "pdf",
    },
    {
      section: {
        section_id: 4,
        section_name: "University Results",
      },
      contentType: "result",
    },
    {
      section: {
        section_id: 5,
        section_name: "Useful Links",
      },
      contentType: "links",
    },
    {
      section: {
        section_id: 6,
        section_name: "Contact us",
      },
      contentType: "contact-us",
    },
  ];

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_ABOUT });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_UNIRESULT });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_USEFUL_LINKS });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_CONTACTUS });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_CIRCULARS });
  }, [dispatch]);

  const { ExamCellCirculars } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellContactUs } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellUsefulLinks } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellUniRes } = useSelector((state) => state.ExamCellReducer);

  const { ExamCellAbout } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellData } = useSelector((state) => state.ExamCellReducer);

  // Log data
  console.log("ExamCellCirculars", ExamCellCirculars);
  const circularData = ExamCellCirculars?.[0]?.[0] || {};
  console.log("ExamCellUsefulLinks", ExamCellUsefulLinks);
  // console.log(ExamCellContactUs,"ExamCellContactUs");

  // console.log("ExamCellUnires", ExamCellUniRes);

  // console.log("ExamCellAbout", ExamCellAbout);
  // console.log("people", ExamCellData);

  // Prepare About Points
  const points =
    ExamCellAbout && ExamCellAbout.length > 0
      ? Object.keys(ExamCellAbout[0])
          .filter((key) => key.startsWith("point") && ExamCellAbout[0][key])
          .map((key) => ExamCellAbout[0][key])
      : [];

  // Validate People Data
  const peopleData =
    ExamCellData?.status === "success" && ExamCellData.data
      ? ExamCellData.data
      : [];

  const toggleVisibilityres = (index) => {
    setIsVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index]; // Toggle the specific index
      return newState;
    });
  };

  const [isVisible, setIsVisible] = useState(
    Array(ExamCellUniRes.length).fill(false)
  );
  const [visibleSemIndex, setVisibleSemIndex] = useState(null);

  // Toggle visibility for a specific semester
  const toggleVisibilitycir = (index) => {
    setVisibleSemIndex(visibleSemIndex === index ? null : index);
  };
  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80 abt-uss">
        <div className="container">
        <h4 className=" fw-300 Examcell-submenu">Exam Cell</h4>
          <div className="cal-action pb-10 mb-40  about-us-texts"></div>
          <div className="sec-head mb-30"></div>

          <div className="row lg-marg" id="tabs">
            {/* Mobile View */}
            <div className="d-lg-none col-12">
            <h4 className=" fw-300 exam-cell-res">Exam Cell</h4>
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end exam-cell-content-res">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.section_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <h5>
                            <span className="fz-18 opacity-7 mr-15">{`0${
                              index + 1
                            }`}</span>
                            {section.section.section_name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop View */}
            <div className="col-lg-4 d-none d-lg-block side-txt">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end exam-cell-tabs">
                  <div className="col-lg-12 iaq-padding">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.section_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <div className="u-line linee psg-iaq-tabs">
                            <span className="icon ti-arrow-circle-right tabss-psg "></span>
                            <span className="text fz-20 psg-margin">
                              {section.section.section_name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-8 col-12">
              <div className="serv-tab-cont md-mb80 sticky-item">
                {sections.map((section, index) => (
                  <div
                    key={section.section.section_id}
                    className={`tab-content ${
                      activeTab === `tabs-${index + 1}` ? "current" : ""
                    }`}
                    id={`tabs-${index + 1}`}
                  >
                    <div className="item">
                      <div className="sub-bg">
                        <div className="icon-img-60 mb-40">
                          <img src={submenuservicon} alt="" />
                        </div>
                        <div className="text">
                          {/* Handle people */}
                          {section.contentType === "cards" && (
                            <>
                              <h5
                                style={{ color: "#3a4197" }}
                                className="mb-20"
                              >
                                People
                              </h5>
                              <div className="row bord-thin-top">
                                {peopleData.length > 0 ? (
                                  <div className="row mb-50 cards-people">
                                    {peopleData.map((person, index) => (
                                      <div
                                        key={index}
                                        className="col-lg-3 col-md-6 col-sm-6 col-12"
                                      >
                                        <div className="card-2 exam-cell-card mt-40 mb-40">
                                          <div className="card-overlay-2"></div>
                                          <div className="card-inner-2">
                                            {person.name && (
                                              <h6 className="fw-700">
                                                {person.name}
                                              </h6>
                                            )}
                                            {person.designation && (
                                              <p>{person.designation}</p>
                                            )}
                                            {person.title && (
                                              <p className="main-color">
                                                {person.title}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="col-12">
                                    <p>No people data available.</p>
                                  </div>
                                )}

                                {/* Loading State */}
                                {points.length === 0 &&
                                  peopleData.length === 0 && (
                                    <div>Loading...</div>
                                  )}
                              </div>
                            </>
                          )}
                          {/* Handle circulars */}
                          {section.contentType === "pdf" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>
                                Internal Circulars
                              </h5>
                              <br />

                              {Array.from({ length: 10 }, (_, semIndex) => (
                                <div
                                  className="panel panel-default"
                                  key={semIndex}
                                >
                                  <div className="panel-heading" role="tab">
                                    <h4 className="panel-title">
                                      <a
                                        role="button"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        onClick={() =>
                                          toggleVisibilitycir(semIndex)
                                        }
                                        aria-expanded={
                                          visibleSemIndex === semIndex
                                        }
                                        aria-controls={`collapse-${semIndex}`}
                                        className=""
                                      >
                                        <i
                                          style={{ color: "white" }}
                                          className={`more-less fa ${
                                            visibleSemIndex === semIndex
                                              ? "fa-angle-up"
                                              : "fa-angle-down"
                                          }`}
                                        ></i>
                                        &nbsp;
                                        <strong className="text-white">
                                          Semester {semIndex + 1}
                                        </strong>
                                      </a>
                                    </h4>
                                  </div>
                                  {visibleSemIndex === semIndex && (
                                    <div
                                      className="panel-collapse collapse show"
                                      role="tabpanel"
                                      aria-labelledby={`heading-${semIndex}`}
                                    >
                                      <div className="panel-body">
                                        <div className="collapse-inner">
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "15px",
                                              alignItems: "center",
                                            }}
                                          >
                                            {Array.from(
                                              { length: 3 },
                                              (_, internalIndex) => (
                                                <a
                                                  href={pdf}
                                                  key={internalIndex}
                                                  target="_blank"
                                                  className="btn btn-link internal-link"
                                                  tabIndex="0"
                                                  rel="noreferrer"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Internal {internalIndex + 1}
                                                </a>
                                              )
                                            )}
                                          </div>
                                          <hr />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                
                              ))}
                             <br />
    <h5 style={{ color: "#3a4197" }}>Anna University Circulars</h5>
    <br />
    <div>
      {Array.from({ length: 3 }, (_, circularIndex) => (
        <a
          href={pdf}
          key={circularIndex}
          target="_blank"
          className="btn btn-link circular-link"
          tabIndex="0"
          rel="noreferrer"
          style={{
            display: "block", // Ensures each circular is displayed on a new line
            color: "black",
            textDecoration: "none",
            marginBottom: "10px", // Adds space between the links
          }}
        >
          Circular {circularIndex + 1}
        </a>
      ))}
    </div>

                            </div>
                          )}

                          {/* handle university res */}
                          {section.contentType === "result" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>
                                Students Academic Performance in End Semester
                                Examinations
                              </h5>
                              <br />
                              {ExamCellUniRes.map((item, index) => (
                                <div
                                  className="panel panel-default"
                                  key={item.id}
                                >
                                  <div
                                    className="panel-heading"
                                    role="tab"
                                    id={`heading-${item.id}`}
                                  >
                                    <h4 className="panel-title">
                                      <a
                                        role="button"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        onClick={() =>
                                          toggleVisibilityres(index)
                                        }
                                        aria-expanded="true"
                                        aria-controls={`collapse-${item.id}`}
                                        className=""
                                      >
                                        <i
                                          style={{ color: "white" }}
                                          className="more-less fa fa-angle-down"
                                        ></i>
                                        &nbsp;
                                        <strong className="text-white">
                                          {item.title}
                                        </strong>
                                      </a>
                                    </h4>
                                  </div>
                                  {isVisible[index] && ( // Dynamically toggle visibility for each item
                                    <div
                                      className="panel-collapse collapse show"
                                      role="tabpanel"
                                      aria-labelledby={`heading-${item.id}`}
                                    >
                                      <div className="panel-body">
                                        <div className="collapse-inner">
                                          {/* Link for date 1 and pdf 1 */}
                                          <a
                                            href={item.pdf}
                                            target="_blank"
                                            className="btn btn-secondary me-2"
                                            tabIndex="0"
                                          >
                                            <span style={{ color: "white" }}>
                                              {item.date}
                                            </span>
                                            &nbsp;
                                            <img
                                              src={share}
                                              alt="share"
                                              className="shareiconres"
                                            ></img>
                                          </a>
                                          {/* Link for date 2 and pdf 2 */}
                                          {item.date2 && item.pdf2 && (
                                            <a
                                              href={item.pdf2}
                                              target="_blank"
                                              className="btn btn-secondary me-2"
                                              tabIndex="0"
                                            >
                                              <span style={{ color: "white" }}>
                                                {item.date2}
                                              </span>
                                              &nbsp;
                                              <img
                                                src={share}
                                                alt="share"
                                                className="shareiconres"
                                              ></img>
                                            </a>
                                          )}
                                        </div>
                                        <hr />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}

                          {/* handling uni links */}
                          {section.contentType === "links" && (
  <div>
    <h5 style={{ color: "#3a4197" }}>Useful Links</h5>
    <br />
    {ExamCellUsefulLinks?.data?.length > 0 &&
      Object.keys(ExamCellUsefulLinks.data[0])
        .filter(
          (key) =>
            key.startsWith("point") &&
            ExamCellUsefulLinks.data[0][key] // Ensure the point is not null
        )
        .map((key, index) => {
          // Extract the corresponding URL or PDF key
          const idSuffix = key.replace("point", "");
          const urlKey = `url_${idSuffix}`;
          const pdfKey = `pdf_${idSuffix}`;

          // Determine whether to use URL or PDF
          const link =
            ExamCellUsefulLinks.data[0][urlKey] ||
            ExamCellUsefulLinks.data[0][pdfKey];

          return (
            <div key={index}>
              {link ? (
                <a
                  target="_blank"
                  href={link}
                  className="usefullinks-examcell"
                  rel="noopener noreferrer"
                >
                  <b>• {ExamCellUsefulLinks.data[0][key]}</b>
                </a>
              ) : (
                <span>{ExamCellUsefulLinks.data[0][key]}</span>
              )}
              <br />
              <br />
            </div>
          );
        })}
  </div>
)}


                          {/* handling contact us */}
                          {section.contentType === "contact-us" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>
                                {ExamCellContactUs?.[0]?.title}
                              </h5>
                              <div>
                                <strong>
                                  {ExamCellContactUs?.[0]?.sub_title}
                                </strong>
                                <br />
                                {ExamCellContactUs?.[0]?.landmark}
                              </div>
                              <div>{ExamCellContactUs?.[0]?.city}</div>
                              <div>{ExamCellContactUs?.[0]?.district}</div>
                              <div>{ExamCellContactUs?.[0]?.state}</div>
                              <br />
                              <div>
                                Phone No :{" "}
                                <a
                                  href={`tel:${ExamCellContactUs?.[0]?.phonenumber}`}
                                >
                                  {ExamCellContactUs?.[0]?.phonenumber}
                                </a>
                              </div>
                              <div>
                                Mail:{" "}
                                <a
                                  href={`mailto:${ExamCellContactUs?.[0]?.email}`}
                                >
                                  <strong>
                                    {ExamCellContactUs?.[0]?.email}
                                  </strong>
                                </a>
                              </div>
                            </div>
                          )}

                          {/* handling about */}
                          {section.contentType === "about" &&
                            points.length > 0 && (
                              <div>
                                <h5 style={{ color: "#3a4197" }}>About</h5>
                                <ul className="about-examcell">
                                  {points.map((point, index) => (
                                    <React.Fragment key={index}>
                                      <li>• &nbsp; {point}</li>
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}
export default Examcell;
