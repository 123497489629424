import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function Archive() {
  const [activeSection, setActiveSection] = useState("theTrust"); // Active section (e.g., Circulars, Timetables)
  const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Sidebar menu state
  const [openKeys, setOpenKeys] = useState([]); // Keys for open menu items
  const [visibleSemIndex, setVisibleSemIndex] = useState(null); // Index of visible semester

  // Data for sections and visibility
  const [sections, setSections] = useState([
    { name: "Internal Circular 2023-2024", visible: false },
    { name: "Internal Circular 2022-2023", visible: false },
    { name: "Anna University Circular 2023-2024", visible: false },
    { name: "Anna University Circular 2022-2023", visible: false },
  ]);

  // Timetable data (example structure)
  const timetables = [
    { year: "2023 - 2024", sem: ["ODD Sem,EVEN Sem", "ODD Sem,EVEN Sem","ODD Sem,EVEN Sem","ODD Sem,EVEN Sem","ODD Sem,EVEN Sem",] },
    { year: "2022 - 2023", sem: ["ODD Sem,EVEN Sem","ODD Sem,EVEN Sem","ODD Sem,EVEN Sem","ODD Sem,EVEN Sem",] },
  ];

  const location = useLocation();
  const routeNames = {
    "/archive": { menuName: "Pedagogy", subMenuName: "Archive" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const handleSectionClick = useCallback((section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  // Filter sections based on the search query
  const filteredSections = sections.filter((section) =>
    section.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleSemesterVisibility = (index) => {
    setVisibleSemIndex((prev) => (prev === index ? null : index));
  };
 // Filtered timetable rows based on the search query
 const filteredTimetables = timetables.filter((timetable) =>
  timetable.year.toLowerCase().includes(searchQuery.toLowerCase())
);
  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          className="admins-res"
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="work-minimal section-padding sub-bg administration-page">
          <div className="container-xxl">
            <div className="row admin-top">
              <div className="filtering col-12 mb-50 mt-60 text-center">
                <div>
                  <span
                    className={activeSection === "theTrust" ? "active" : ""}
                    onClick={() => handleSectionClick("theTrust")}
                  >
                    Circulars
                  </span>
                  <span
                    className={
                      activeSection === "secretaryInfo" ? "active" : ""
                    }
                    onClick={() => handleSectionClick("secretaryInfo")}
                  >
                    Timetables
                  </span>
                </div>
                {/* Search box */}
                <div className="search-container mt-50
                ml-80">
  <div className="search-input-wrapper">
    <input
      type="text"
      placeholder="Search Year..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
    <span className="search-icon-archive">
      <i className="fa fa-search"></i>
    </span>
  </div>
</div>


              </div>
            </div>

            <div className="gallery row stand-marg administrations archivee-tabss">
              {/* Display filtered sections */}
              {filteredSections.map((section, sectionIndex) => (
                <div
                  key={sectionIndex}
                  className="col-12 items trust-info"
                  style={{
                    display:
                      activeSection === "theTrust" || activeSection === null
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="container">
                    <h5
                      style={{ color: "#3a4197", cursor: "pointer" }}
                      onClick={() =>
                        setSections((prev) =>
                          prev.map((sec, i) =>
                            i === sectionIndex
                              ? { ...sec, visible: !sec.visible }
                              : sec
                          )
                        )
                      }
                    >
                      {section.name}
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    </h5>
                    <br />
                    {section.visible && (
                      <div className="container">
                        {Array.from({ length: 10 }, (_, semIndex) => (
                          <div className="panel panel-default" key={semIndex}>
                            <div className="panel-heading" role="tab">
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  onClick={() =>
                                    toggleSemesterVisibility(semIndex)
                                  }
                                  aria-expanded={visibleSemIndex === semIndex}
                                  aria-controls={`collapse-${semIndex}`}
                                  className="more-less-toggle"
                                >
                                  <i
                                    style={{ color: "white" }}
                                    className={`more-less fa ${
                                      visibleSemIndex === semIndex
                                        ? "fa-angle-up"
                                        : "fa-angle-down"
                                    }`}
                                  ></i>
                                  &nbsp;
                                  <strong className="text-white">
                                    Semester {semIndex + 1}
                                  </strong>
                                </a>
                              </h4>
                            </div>
                            {visibleSemIndex === semIndex && (
                              <div
                                className="panel-collapse collapse show"
                                role="tabpanel"
                                aria-labelledby={`heading-${semIndex}`}
                              >
                                <div className="panel-body">
                                  <div className="collapse-inner">
                                    {Array.from({ length: 3 }, (_, index) => (
                                      <a
                                        key={index}
                                        className="btn btn-link"
                                        href="#"
                                        style={{
                                          color: "black",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Circular {index + 1}
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {/* Secretary Info */}
              <div
                className="col-12 items brand sec-info"
                style={{
                  display:
                    activeSection === "secretaryInfo" || activeSection === null
                      ? "block"
                      : "none",
                }}
              >
                   {activeSection === "secretaryInfo" && (
                <section className="section-padding">
                  <div className="container">
                    <table className="rwd-table timetable-res">
                      <thead className="desktop-only">
                        <tr className="desktop-only1">
                          <th className="border-right timetable-year abc">
                            Year
                          </th>
                          <th className="border-right timetable-years abc">
                            1'st year
                          </th>
                          <th className="border-right timetable-years abc">
                            2'nd year
                          </th>
                          <th className="border-right timetable-years abc">
                            3'rd Year
                          </th>
                          <th className="border-right timetable-years abc">
                            4'th Year
                          </th>
                          <th className="timetable-years">Final Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTimetables.map((timetable, index) => (
                          <tr key={index}>
                            <td data-th="Year">{timetable.year}</td>
                            {timetable.sem.map((sem, semIndex) => (
                              <td key={semIndex}>
                                <a href="#" target="_blank">
                                  {sem}
                                </a>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
                   )}
              </div>
            </div>
          </div>
        </section>
      </main>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Archive;