import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../Store/Home/action";
import backgroundImageUrlFinal from "../assets/imgs/SEOimage.webp";
const HeaderBackground = ({ menuName, subMenuName, handleMenuToggle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_LISTPAGE_BACKGROUND_IMAGE });
  }, [dispatch]);

  const { ListpageBGImage } = useSelector((state) => state.HomePageReducer);
  const backgroundImageUrl =
    ListpageBGImage.length > 0 ? ListpageBGImage[0].image : "";

  return (
    <header
      className="page-header-cerv bg-img bg-center section-padding"
      style={{ backgroundImage: `url(${backgroundImageUrlFinal})`
     }}
      data-overlay-dark="4"
    >
      <div className="container pt-100 ontop">
        <div className="text-left">
          <div>
            <a href="/">Home</a>
            <span className="padding-rl-10">|</span>
            <button
              onClick={handleMenuToggle}
              style={{
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                color: "white",
              }}
            >
              {menuName}
            </button>

            {subMenuName && (
              <>
                <span className="padding-rl-10">|</span>
                <span>{subMenuName}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderBackground;
