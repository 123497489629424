import { AcademicCalendarActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const AcademicCalendarSagas = function* () {
  yield all([
    takeEvery(
      AcademicCalendarActions.GET_ACADEMIC_CALENDAR,
      getAcademicCalendarInfo
    ),
  ]);
};

const getAcademicCalendarInfo = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/academic_calendar`));

    yield put({
      type: AcademicCalendarActions.SET_ACADEMIC_CALENDAR,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
