import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { HomePageActions } from "./action";

export const HomeSagas = function* () {
  yield all([
    takeEvery(
      HomePageActions.GET_HOME_TOPBAR_SCROLL_TEXT,
      getHomeTopbarScrollText
    ),
    takeEvery(
      HomePageActions.GET_HOME_MENUS_AND_SUBMENUS,
      getHomeMenusAndSubmenus
    ),
    takeEvery(HomePageActions.GET_HOME_HEADER_BANNER, getHomeHeaderBanner),
    takeEvery(HomePageActions.GET_HOME_BACKGROUNDIMAGE, getBackgroundImage),
    takeEvery(HomePageActions.GET_HOME_BACKGROUNDIMAGE, getTestimonial),
    takeEvery(HomePageActions.GET_HOME_ABOUTPRINCIPAL, getAboutPrincipal),
    takeEvery(HomePageActions.GET_HOME_LATESTVIDEO, getLatestVideo),
    takeEvery(HomePageActions.GET_HOME_BLOG, getBlog),
    takeEvery(HomePageActions.GET_HOME_STUDENTPORTFOLIO, getStudentPortfolio),
    takeEvery(HomePageActions.GET_HOME_NEWS_AND_EVENTS, getNewsAndEvents),
    takeEvery(HomePageActions.GET_DETAILPAGE, fetchDetailPage),

    takeEvery(
      HomePageActions.STUDENT_PORTFOLIO_DETAILPAGE,
      StudentPortfolioDetailPage
    ),
    takeEvery(
      HomePageActions.GET_LISTPAGE_BACKGROUND_IMAGE,
      getlistpageBGImage
    ),
  ]);
};

const getHomeTopbarScrollText = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/topbar`));

    yield put({
      type: HomePageActions.SET_HOME_TOPBAR_SCROLL_TEXT,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getHomeMenusAndSubmenus = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/submenu`));

    yield put({
      type: HomePageActions.SET_HOME_MENUS_AND_SUBMENUS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getHomeHeaderBanner = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/banner`));

    yield put({
      type: HomePageActions.SET_HOME_HEADER_BANNER,
      payload: result.data,
    });
  } catch (error) {
    console.log("Error", error);
  }
};
const getBackgroundImage = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/bg_banner`));

    yield put({
      type: HomePageActions.SET_HOME_BACKGROUNDIMAGE,
      payload: result.data,
    });
   
  } catch (error) {
    console.error("Error", error);
  }
};
const getTestimonial = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/testimonials`));

    yield put({
      type: HomePageActions.SET_HOME_TESTIMONIAL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
const getAboutPrincipal = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/principal_message`));

    yield put({
      type: HomePageActions.SET_HOME_ABOUTPRINCIPAL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
const getLatestVideo = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/latest_videos`));
    console.log("getLatestVideo",result);
    
    yield put({
      type: HomePageActions.SET_HOME_LATESTVIDEO,
      payload: result.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};
const getBlog = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/archigazette`));
    console.log("getBlog",result);
    
    yield put({
      type: HomePageActions.SET_HOME_BLOG,
      payload: result.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};

const getStudentPortfolio = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/projects`));

    // console.log("student portfolio", result);  
    const { headings, projects } = result.data; 

    yield put({
      type: HomePageActions.SET_HOME_STUDENTPORTFOLIO,
      payload: {
        headings,
        projects,
      },
    });
  } catch (error) {
    console.error("error", error);
  }
};


const getNewsAndEvents = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/newsandevents`));
    yield put({
      type: HomePageActions.SET_HOME_NEWS_AND_EVENTS,
      payload: result.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};

const fetchDetailPage = function* (data) {
  const { payload } = data;
  try {
    const { id } = payload;
    // Always use 'detailednews' as the endpoint
    const result = yield call(() => axios.get(`${API_URL}/detailednews/${id}`));
    yield put({
      type: HomePageActions.SET_DETAILPAGE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching detail page data:", error);
  }
};

const StudentPortfolioDetailPage = function* (data) {
  try {
    const { projectId } = data.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/detailedprojects/${projectId}`)
    );

    yield put({
      type: HomePageActions.SET_STUDENT_PORTFOLIO_DETAILPAGE,
      payload: result.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};

const getlistpageBGImage = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/portfolio_banner`));
    yield put({
      type: HomePageActions.SET_LISTPAGE_BACKGROUND_IMAGE,
      payload: result.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};

