import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { CurricularActions } from "./action";

export const CurricularSagas = function* () {
  console.log("CurricularSagas running...");
  yield all([takeEvery(CurricularActions.GET_CURRICULAR, getLifeAtCampus)]);
};

const getLifeAtCampus = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/getCurricularsData`));
    console.log("curricularsagas", result);

    yield put({
      type: CurricularActions.SET_CURRICULAR,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error in API Call:", error);
  }
};
