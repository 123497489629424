import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CocurricularActions } from "../../../Store/CoCurricular/action";
import HeaderBackground from "../../../common/SubpagesBGimage";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function CoCurricular() {
  const [activeCategory, setActiveCategory] = useState("Co-Curricular");
  const [openKeys, setOpenKeys] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CocurricularActions.GET_COCURRICULAR });
  }, [dispatch]);

  const { CocurricularData } = useSelector((state) => state.CocurricularReducer);

  const CocurricularFront = CocurricularData?.data?.CocurricularFront || [];
  const CocurricularDetail = CocurricularData?.data?.CocurricularDetail || [];

  useEffect(() => {
    if (CocurricularData) {
      setIsLoading(false);
    }
  }, [CocurricularData]);

  const handleThumbnailClick = (cocurricularId) => {
    const detailImages = CocurricularDetail.filter(
      (item) => item.cocurricular_id === cocurricularId
    ).map((item) => ({
      src: item.image,
      opts: { caption: item.name || "Detail Image" },
    }));

    if (detailImages.length > 0) {
      Fancybox.show(detailImages, {
        infinite: true,
        buttons: ["zoom", "close", "fullscreen"],
        loop: true,
      });
    }
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName="Life At Campus"
          subMenuName="Co-Curricular"
          handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        />
        <section className="work-stand section-padding sub-bg cocurricular-title">
          <div className="container">
          <div className="bord  d-flex align-items-center">
          <h4 className=" fw-300 ">Co-Curricular</h4>
          
          </div>
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
             
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg">
              {isLoading ? (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              ) : CocurricularFront.length > 0 ? (
                CocurricularFront.map((item) => (
                  <div
                    key={item.id}
                    className="col-lg-3 col-md-6 col-sm-12 mb-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleThumbnailClick(item.id)}
                  >
                    <div className="archi-card-container mt-30">
                      <div
                        className="archi-card-image-lifeatcampus pro-img"
                        style={{
                          backgroundImage: `url(${item.catagory_image})`,
                        }}
                      ></div>
                      <div className="archi-card-content">
                        <p className="archi-card-author">
                          {item.catagory_name.slice(0, 23)}...
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key="Life At Campus"
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu="Life At Campus"
      />
    </div>
  );
}

export default CoCurricular;
