import { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HeaderBackground from "../../../common/SubpagesBGimage";

const StudentProjects = ({ projectname, description, images }) => {
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
    
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust time as needed for simulation

    return () => clearTimeout(timer); 
  }, [projectname, description, images]);

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]');
  }, [projectname, description,images]);
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <HeaderBackground
       menuName="Student Portfolio"
       handleMenuToggle={handleMenuToggle}
      />
<Container>
      <section className="main-grid">
        <div className="gallery-head">
          {/* Skeleton loaders will be shown while loading is true */}
          {loading ? (
           
           <div className="loader-container">
           <div className="spinner"></div> {/* Spinner */}
          
         </div>
          ) : (
            <>
              <h1 className="text-center h1 pb-40 gallery-heading">{projectname}</h1>
              <p className="mb-30 ml-20 mr-15 gallery-text">{description}</p>
            </>
          )}
        </div>

        <div className="container">
          {/* Display images only if not loading */}
          {loading ? (
            <Skeleton count={5} height={200} className="mb-4 card-image card" /> 
          ) : images.length === 0 ? (
            <p>No images available</p>
          ) : (
            images.map((item, index) => (
              <div className="card" key={index}>
                <div className="card-image">
                  <a
                    href={item.image}
                    data-fancybox="gallery"
                    data-caption={item.content || "Project Image"}
                  >
                    <img src={item.image} alt="Img Gallery" />
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </Container>
    </div>
    
  );
};

export default StudentProjects;









