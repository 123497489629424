import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import img1 from "../../../assets/imgs/10.jpg";
import img2 from "../../../assets/imgs/20241018_101201.jpg";
import img4 from "../../../assets/imgs/4.jpg";

const LabsDetailPage = () => {
  const [selectedData, setSelectedData] = useState(null);

  const staticData = {
    categoryName: "Static Category Name",
    description: "This is a static description for the category.",
    detailImages: [
      {
        image: img1,
        name: "Static Image 1",
      },
      {
        image: img2,
        name: "Static Image 2",
      },
      {
        image: img1,
        name: "Static Image 3",
      },
      {
        image: img4,
        name: "Static Image 4",
      },
    ],
  };

  useEffect(() => {
    // Initialize selectedData with staticData
    setSelectedData(staticData);
  }, []);

  useEffect(() => {
    if (selectedData?.detailImages?.length > 0) {
      Fancybox.bind('[data-fancybox="gallery"]', {
        infinite: false,
        buttons: ["zoom", "close"],
      });
    }

    return () => {
      Fancybox.destroy();
    };
  }, [selectedData]);

  const isValidImage = (url) => url && url !== "null" && url !== "undefined";

  return (
    <div className="detail-page">
      <Container>
        <main>
          <section className="main-grid mt-50">
            <div className="gallery-head">
              <h1 className="text-center h1 pb-40 gallery-heading">
                {selectedData?.categoryName || "Loading..."}
              </h1>
              <p className="text-center">{selectedData?.description || ""}</p>
            </div>
            <div
              className="container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                justifyContent: "center",
              }}
            >
              <div className="container">
                {selectedData?.detailImages?.length > 0 ? (
                  selectedData.detailImages.map((item, index) =>
                    isValidImage(item.image) ? (
                      <div className="card" key={index}>
                        <div className="card-image">
                          <a href={item.image} data-fancybox="gallery">
                            <img
                              src={item.image}
                              alt={item.name || `Detail ${index + 1}`}
                              style={{ width: "100%", height: "auto" }}
                              className="life-at-campus-height"
                            />
                          </a>
                        </div>
                      </div>
                    ) : null
                  )
                ) : (
                  <p>No detail images available.</p>
                )}
              </div>
            </div>
          </section>
        </main>
      </Container>
    </div>
  );
};

export default LabsDetailPage;
