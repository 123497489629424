import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

const Loader = () => (
  <div className="loader-container-testimonial">
    <div className="loader-testimonial"></div>
  </div>
);

// Testimonials Component
const Testimonials = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const { Testimonial } = useSelector((state) => state.HomePageReducer);

  // Fetch testimonials on component mount
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_TESTIMONIAL });
  }, [dispatch]);

  useEffect(() => {
    if (Testimonial && Testimonial.length > 0) {
      setLoading(false);
    }
  }, [Testimonial]);

  const memoizedSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: false,
      lazyLoad: "ondemand",
    }),
    []
  );

  const handlePrevSlide = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleNextSlide = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const openPopup = useCallback((url) => {
    setVideoUrl(url);
    setShowPopup(true);
  }, []);

  const closePopup = useCallback(() => {
    setVideoUrl("");
    setShowPopup(false);
  }, []);

  const memoizedTestimonials = useMemo(() => {
    return Testimonial.map((item, index) => {
      const points = Array.from(
        { length: 10 },
        (_, i) => item[`point${i + 1}`]
      ).filter(Boolean);

      return (
        <div className="item" key={index}>
          <div className="content">
            <div className="row align-items-center">
              <div className="col-lg-6 left-clumn">
                <div className="caption">
                  <h2>
                    <span className="tes-big-text">
                      <span className="testimonial-text-heading home-heading-text-testimonial">
                        {item.title}
                      </span>
                    </span>
                  </h2>
                  <p
  className="testimonial-text"
  dangerouslySetInnerHTML={{ __html: item.content }}
></p>

                  {points.length > 0 && (
                    <div className="testimonial-points">
                      {points.map((point, idx) => (
                        <p key={idx} className="testimonial-point">
                          <span style={{ paddingRight: "10px" }}>
                            {idx + 1}.
                          </span>
                          {point}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="col-lg-6 right-clumn"
                onClick={() =>
                  item.file.endsWith(".mp4") && openPopup(item.file)
                }
              >
                <div className="img-vid">
                  {item.thumbnailFile ? (
                    <img
                      src={item.thumbnailFile}
                      alt=""
                      className="testimonial-image"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={item.file}
                      alt=""
                      className="testimonial-image"
                    />
                  )}
                  {item.file.endsWith(".mp4") && (
                    <div className="curv-butn">
                      <div className="icon">
                        <i className="fas fa-play"></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [Testimonial, openPopup]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="testimonial-global">
      <section className="testimonials section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 position-re view-tog-wrap">
              <Slider {...memoizedSettings} ref={sliderRef}>
                {memoizedTestimonials}
              </Slider>
              <div className="swiper-arrow-control control-abslout">
                <div className="swiper-button-prev" onClick={handlePrevSlide}>
                  <span className="ti-arrow-circle-left testimonial-btn"></span>
                </div>
                <div className="swiper-button-next" onClick={handleNextSlide}>
                  <span className="ti-arrow-circle-right testimonial-btn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <div className="close-btn-testimonial" onClick={closePopup}>
            <i className="fas fa-times"></i>
          </div>
        )}
        {showPopup && (
          <div className="video-popup">
            <div className="video-container-testimonial">
              <video controls autoPlay className="video-content">
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Testimonials;
