import { ExtraCurricularActions } from "./action";

const initialState = {
  ExtraCurricularData: [],
};

export const ExtraCurricularReducer = (state = initialState, action) => {
  switch (action.type) {
    case ExtraCurricularActions.SET_EXTRACURRICULAR:
      return {
        ...state,
        ExtraCurricularData: action.payload,
      };
    default:
      return state;
  }
};
