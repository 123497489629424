import { AcademictimetableActions } from "./action";

const initialState = {
  AcademictimetableData: [],
};

export const AcademictimetableReducer = (state = initialState, action) => {
  switch (action.type) {
    case AcademictimetableActions.SET_ACADEMIC_TIMETABLE:
      return {
        ...state,
        AcademictimetableData: action.payload,
      };

    default:
      return state;
  }
};
