import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

function AboutPrincipal() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_ABOUTPRINCIPAL });
  }, [dispatch]);

  const { AboutPrincipal } = useSelector((state) => state.HomePageReducer);
  
  const [animateText, setAnimateText] = useState(false);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  // Throttling scroll event to improve performance
  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset;
    const section = document.querySelector(".about");

    if (section) {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      const isInSection = scrollTop > sectionTop - window.innerHeight + sectionHeight / 2;

      if (isInSection && !animationTriggered) {
        setAnimateText(true);
        setAnimationTriggered(true);
      }
    }
  }, [animationTriggered]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // Popup video logic
  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };

  // Truncate text to the last full stop within limit
  const truncateTextAtFullStop = (text, limit) => {
    if (text.length <= limit) return text;

    const truncated = text.substring(0, limit);
    const lastPeriodIndex = truncated.lastIndexOf(".");

    return lastPeriodIndex !== -1 ? text.substring(0, lastPeriodIndex + 1) : truncated;
  };

  return (
    <section className="about sub-bg home-gap-team" data-scroll-index="2">
      <div className="container section-padding">
        {AboutPrincipal.map((item, index) => (
          <div className="row md-marg manage-wrap align-items-start  team-iap-res" key={index}>
            <div className="col-lg-6">
              <div className={`cont md-mb50 ${animateText ? "animate" : ""}`}>
                {/* <h6 className="sub-title main-color mb-15">Team</h6> */}
                <h2 className="fw-600 d-rotate wow">
                  <span className="rotate-text principal-name fw-600 princi home-heading-text">
                    {item.team_iap_name}
                  </span>
                </h2>
                <div className="mt-30">
                  <p style={{ textAlign: "justify" }}>
                    {truncateTextAtFullStop(item.team_iap_description, 600)}
                  </p>
                  <div className="mt-30">
                    {/* <Link to="/principal" className="go-more">
                      <span className="text">Read More</span>
                      <span className="icon ti-arrow-circle-right principal-text"></span>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img 
                  src={item.team_iap_file} 
                  alt={item.team_iap_name} 
                  loading="lazy"  // Lazy loading for images
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Close Button */}
      {showPopup && (
        <div className="close-btn-testimonial" onClick={closePopup}>
          <i className="fas fa-times"></i>
        </div>
      )}

      {/* Video Popup */}
      {showPopup && (
        <div className="video-popup">
          <div className="video-container-testimonial">
            <video controls autoPlay className="video-content">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </section>
  );
}

export default AboutPrincipal;
