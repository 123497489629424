import { ProgramsActions } from "./action";
import { takeEvery, call, all, put,  } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const ProgramsSagas = function* () {
  yield all([takeEvery(ProgramsActions.GET_PROGRAMS, getProgramsInfo)]);
};

const getProgramsInfo = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/ourprograms`));

    yield put({
      type: ProgramsActions.SET_PROGRAMS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
