import { HomePageActions } from "./action";

const initialState = {
  HeaderScrollText: [],
  MenusAndSubmenus: [],
  Banner: [],
  BackgroundImage: [],
  Testimonial: [],
  AboutPrincipal: [],
  LatestVideo: [],
  Blog: [],
  blogHeadings:[],
  portfolioHeadings: [],
  StudentPortfolio: [],
  NewsAndEvents: [],
  headings:[],
  DetailPage: null,
  StudentPortfolioDetailpage: [],
  ListpageBGImage: [],
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomePageActions.SET_HOME_TOPBAR_SCROLL_TEXT:
      return {
        ...state,
        HeaderScrollText: action.payload,
      };
    case HomePageActions.SET_HOME_MENUS_AND_SUBMENUS:
      return {
        ...state,
        MenusAndSubmenus: action.payload,
      };
    case HomePageActions.SET_HOME_HEADER_BANNER:
      return {
        ...state,
        Banner: action.payload,
      };
    case HomePageActions.SET_HOME_BACKGROUNDIMAGE:
      return {
        ...state,
        BackgroundImage: action.payload,
      };
    case HomePageActions.SET_HOME_TESTIMONIAL:
      return {
        ...state,
        Testimonial: action.payload,
      };

    case HomePageActions.SET_HOME_ABOUTPRINCIPAL:
      return {
        ...state,
        AboutPrincipal: action.payload,
      };
    case HomePageActions.SET_HOME_LATESTVIDEO:
      return {
        ...state,
        vidHeadings: action.payload.headings || [],
        LatestVideo: action.payload.latest_videos || [],
      };
    case HomePageActions.SET_HOME_BLOG: {
      return {
        ...state,
        Blog:  Array.isArray(action.payload.archigazettes) ? action.payload.archigazettes : [],
        blogHeadings: Array.isArray(action.payload.headings)?action.payload.headings:[],
      };
    }
    case HomePageActions.SET_HOME_STUDENTPORTFOLIO: {
      return {
        ...state,
        StudentPortfolio: Array.isArray(action.payload.projects) ? action.payload.projects : [], 
        portfolioHeadings: Array.isArray(action.payload.headings) ? action.payload.headings : [],  
      };
    }
    case HomePageActions.SET_HOME_NEWS_AND_EVENTS: {
      return {
        ...state,
        headings: action.payload.headings || [],
        NewsAndEvents: action.payload.news_and_events || [],
      };
    }

    case HomePageActions.SET_DETAILPAGE: {
      return {
        ...state,
        DetailPage: action.payload,
      };
    }

    case HomePageActions.SET_STUDENT_PORTFOLIO_DETAILPAGE: {
      return {
        ...state,
        StudentPortfolioDetailpage: action.payload,
      };
    }

    case HomePageActions.SET_LISTPAGE_BACKGROUND_IMAGE: {
      return {
        ...state,
        ListpageBGImage: action.payload,
      };
    }

    default:
      return state;
  }
};
