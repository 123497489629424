import React from "react";
import graduation from "../../../assets/imgs/submenu/graduation.webp";
import MarqueeText from "../../../common/marquee";

function SubmenuHeader() {
  return (
    <div>
      <MarqueeText className="marbg"/>
    
      <div className="image-container">
        <img src={graduation} alt="graduation" className="cropped-image" />
      </div>
    </div>
  );
}

export default SubmenuHeader;
