import { FooterActions } from "./action";

const initialState = {
  Footerdata: [],
  ReportsTitle:[],
  ReportsData:[],
};

export const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FooterActions.SET_FOOTER_DETAILS:
      return {
        ...state,
        Footerdata: action.payload,
      };

      case FooterActions.SET_REPORTS_TITLE:
        return{
          ...state,
          ReportsTitle:action.payload,
        }

        case FooterActions.SET_REPORTS_DATA:
          console.log("Setting ReportsData in reducer:", action.payload); 
          return {
            ...state,
            ReportsData: action.payload.data || [], 
          };
        
        
        
          
  
    default:
      return state;
  }
};
