import React,{useState} from 'react'
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
function ComputerCenter() {
      const [isMenuOpen, setIsMenuOpen] = useState(false);
        const [openKeys, setOpenKeys] = useState([]);
    const location = useLocation();

    document.title = "ComputerCenter";
    const routeNames = {
      "/computer-center": {
        menuName: "Learning Events",
        subMenuName: "Library",
      },
    };

    const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };


  return (
    <div>
             <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />

<section className="work-stand section-padding sub-bg ">
<div className="container-xxl">
<div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
            <h6 className="sub-title mb-15 main-color">Computer Center</h6>
          </div>
    </div>
    </section>


<SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  )
}

export default ComputerCenter



// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import SideNavigation from "../../Menu-sidenavbar/SideNavigation";
// import HeaderBackground from "../../../common/SubpagesBGimage";
// import { useDispatch, useSelector } from "react-redux";
// import { CurricularActions } from "../../../Store/Curricular/action";

// function Curricular() {
//   const location = useLocation();
//   const [activeCategory, setActiveCategory] = useState("Curricular");
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [openKeys, setOpenKeys] = useState([]);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const routeNames = {
//     "/life-at-campus": {
//       menuName: "Life At Campus",
//       subMenuName: "Curricular",
//     },
//   };

//   const { menuName, subMenuName } =
//     location.state || routeNames[location.pathname] || {};

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const onOpenChange = (keys) => {
//     setOpenKeys(keys);
//   };

//   useEffect(() => {
//     dispatch({ type: CurricularActions.GET_CURRICULAR });
//   }, [dispatch]);

//   const { CurricularData } = useSelector((state) => state.ExamCellReducer);
//   console.log("CurricularData", CurricularData);

//   const handleCommitteeClick = (description) => {
//     navigate(`/detailpagecurricular/${description}`, {
//       state: { description },
//     });
//   };

//   return (
//     <div>
//       <main className="main-bg blog-wrap life-at-campus">
//         <HeaderBackground
//           menuName={menuName}
//           subMenuName={subMenuName}
//           handleMenuToggle={handleMenuToggle}
//         />
//         <section className="work-stand section-padding sub-bg">
//           <div className="container-xxl">
//             <div className="row">
//               <div className="filtering col-12 text-center life-at-campus-filtering">
//                 <div className="filter">
//                   <span
//                     className={activeCategory === "Curricular" ? "active" : ""}
//                     onClick={() => setActiveCategory("Curricular")}
//                   >
//                     Curricular
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="gallery row stand-marg">
//               {CurricularData &&
//                 CurricularData.map((item) => (
//                   <div
//                     key={item.id}
//                     className="col-lg-3 col-md-6 col-sm-12 mb-4"
//                     onClick={() => handleCommitteeClick(item.category_title)}
//                   >
//                     <div className="archi-card-container mt-30">
//                       <div
//                         className="archi-card-image-lifeatcampus pro-img"
//                         style={{
//                           backgroundImage: `url(${item.category_image})`,
//                         }}
//                       ></div>
//                       <div className="archi-card-content">
//                         <p className="archi-card-author">
//                           {item.category_title.slice(0, 23)}...
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </section>
//       </main>
//       <SideNavigation
//         key={menuName}
//         isMenuOpen={isMenuOpen}
//         handleMenuToggle={handleMenuToggle}
//         openKeys={openKeys}
//         onOpenChange={onOpenChange}
//         defaultOpenSubMenu={menuName}
//       />
//     </div>
//   );
// }

// export default Curricular;
