import { VisitingFacultyActions } from "./action";
import { takeEvery, call, all, put,  } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const VisitingFacultySagas = function* () {
    yield all([
      takeEvery(
        VisitingFacultyActions.GET_VISITING_FACULTY,
        getVisitingFaculty
      ),
    ]);
  };
  
  const getVisitingFaculty = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/visiting-faculty`));
  
      yield put({
        type: VisitingFacultyActions.SET_VISITING_FACULTY,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };
  