import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useSelector } from "react-redux";
// import { VisitingFacultyActions } from "../../../Store/visiting-faculty/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function VisitingFaculty() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch({ type: VisitingFacultyActions.GET_VISITING_FACULTY });
  // }, [dispatch]);

  const { VisitingFacultyData } = useSelector(
    (state) => state?.VisitingFacultyReducer
  );

  const routeNames = {
    "/visiting-faculty": {
      menuName: "The People",
      subMenuName: "Visiting Faculty",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <div className="section-padding visit-fac">
          <div className="container">
            <div className="bord  d-flex align-items-center visiting-faculty-texts">
            <h3 className="submenu-heading-text fw-300">Visiting Faculty</h3>
            </div>
            <div className="row">
              {VisitingFacultyData && VisitingFacultyData.length > 0 ? (
                VisitingFacultyData.map((faculty) => (
                  <div className="col-md-3 mt-40" key={faculty.id}>
                    <div className="core-card">
                      <div className="card-container card-ress">
                        <div className="content-3">
                          <img src={faculty.image} alt={faculty.name} />
                          <h6 className="main-color fw-500 core-texts">
                            {faculty.name}
                          </h6>
                          {/* <p className="fw-400 visiting-fclty">
                            {faculty.designation}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <p>Loading....</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default VisitingFaculty;
