import React, { useState, useEffect } from 'react'
import Footer from "../components/Layout/Footer";
// import Cursor from "../common/Cursor";
import Loading from "../common/Loading";
import Programs from '../components/Submenu/Programs/Programs';
import Header from '../components/Layout/Header';
import { useDispatch, useSelector } from "react-redux";
import { ProgramsActions } from "../Store/programs/action";
import gsap from 'gsap';
import { PageOpeningAnimation } from '../common/pageOpeningAnimation'

function Program() {
  document.title = "Programs";
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const { ProgramsData } = useSelector((state) => state.ProgramsReducer);

  
  useEffect(() => {
    dispatch({ type: ProgramsActions.GET_PROGRAMS });
  }, [dispatch]);
  
  useEffect(() => {
    if (ProgramsData?.our_programs?.length !== 0) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [ProgramsData?.our_programs]);

  //for page opening animations
  useEffect(() => {
    if (!loading) {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline({ repeat: 0, yoyo: true });

      tl.to('.bluee', {
        duration: 1,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      }).to('.bluee', {
        duration: 1,
        attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
        ease: 'power2.out',
      });

      // Start SVG animation after the loading text is hidden
      tl.add(() => {
        gsap.to(svg, {
          duration: 0.8,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        });
      });

      // Clean up animation on unmount
      return () => {
        tl.kill(); // Kill the animation to prevent memory leaks
      };
    }
  }, [loading]);


  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* <Cursor /> */}
          <PageOpeningAnimation />
          <Header />
          <Programs />
          <Footer />
        </>
      )}
    </div>
  )
}

export default Program